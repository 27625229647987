import React, { useContext, useEffect, useState } from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { PageTitleProps } from '~/components/common/PageTitle';
import { ProfileImage } from '~/components/common/ProfileImage';
import { ThumbnailImage } from '~/components/common/ThumbnailImage';
import ListPage from '~/components/list/ListPage';
import ListPagination, { PaginationListType } from '~/components/list/ListPagination';
import TableListItems, { ListColumnType } from '~/components/list/TableListItems';
import ViewPage from '~/components/view/ViewPage';
import { AlertContext } from '~/contexts/AlertContext';
import { ApiContext } from '~/contexts/ApiContext';
import { UserType } from '~/types/UserType';
import { DateFormatter } from '~/utils/DateFormatter';

type Props = {
    user?: UserType;
};

const UserContents = (props: Props) => {
    const user = props.user;
    const api = useContext(ApiContext);
    const alert = useContext(AlertContext);
    const [listData, setListData] = useState<PaginationListType>({});

    const [page, setPage] = useState(1);
    const [size] = useState(10);

    const handlePage = (page: number) => {
        setPage(page);
    };

    useEffect(() => {
        api.get(`/users/${user?.userId}/bookmarked-contents`, { page: page, size: size })
            .then((data) => {
                data.content = data.content?.map((obj: any) => ({
                    ...obj,
                    id: obj.contentId,
                    contentLink: (
                        <Link underline='hover' href={'/contents/posted/' + obj.contentId}>
                            {obj.contentId}
                        </Link>
                    ),
                    contentInfo: (
                        <Stack direction='row' alignItems='center' spacing={1}>
                            <ThumbnailImage src={obj.url?.thumbnail} alt={obj.title} />
                            <Typography variant='body2'>{obj.title}</Typography>
                        </Stack>
                    ),
                    channelInfo: (
                        <Stack direction='row' alignItems='center' spacing={0.5}>
                            <ProfileImage src={obj.channel.url?.thumbnail} title={obj.channel.name} />
                            <Typography variant='body2'>
                                <Link underline='hover' href={'/channels/' + obj.channel.channelId}>
                                    {obj.channel.name}
                                </Link>
                            </Typography>
                        </Stack>
                    ),
                    createdAt: DateFormatter.toFormattedDate(obj.createdAt as string),
                }));
                setListData(data);
            })
            .catch((e: any) => {
                alert.error(e.message, -1);
            });
    }, [api, user, page, size, setListData, alert]);

    const listColumn: ListColumnType[] = [
        { column: 'id', isHidden: true, idColumn: true },
        { column: 'contentLink', title: 'ID' },
        { column: 'contentInfo', title: '컨텐츠' },
        { column: 'channelInfo', title: '채널' },
        { column: 'hit', title: '조회수' },
        { column: 'commentCount', title: '댓글수' },
        { column: 'createdAt', title: '게시일' },
    ];

    const pageTitleProps: PageTitleProps = {
        component: 'h2',
        variant: 'subtitle1',
        color: 'default',
    };

    return (
        <ViewPage pageTitle='북마크한 컨텐츠' pageTitleProps={pageTitleProps}>
            {listData.totalElements !== undefined && listData.totalElements > 0 ? (
                <ListPage>
                    <ListPagination listData={listData} currentPage={page} handlePage={handlePage}>
                        {listData.content && (
                            <TableListItems items={listData.content} listColumn={listColumn}></TableListItems>
                        )}
                    </ListPagination>
                </ListPage>
            ) : (
                <Typography component='h2' variant='body2' textAlign='center' sx={{ marginTop: 2 }}>
                    북마크한 컨텐츠가 없습니다.
                </Typography>
            )}
        </ViewPage>
    );
};

export default UserContents;
