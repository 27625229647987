import React, { useContext } from 'react';
import { Avatar, Button, CssBaseline, Link, Box, Typography, Container } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { AuthContext } from '~/contexts/AuthContext';
import { AlertContext } from '~/contexts/AlertContext';

import { useForm } from 'react-hook-form';
import ValidTextField from '~/components/form/ValidTextField';

function Copyright(props: any) {
    return (
        <Typography variant='body2' color='text.secondary' align='center' {...props}>
            {'Copyright © '}
            <Link color='inherit' href='https://perfect-storm.net/'>
                Perfectstorm
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Login() {
    const auth = useContext(AuthContext);
    const alert = useContext(AlertContext);

    type FormValues = {
        email: string;
        password: string;
    };

    const { getValues, setValue, handleSubmit, control } = useForm<FormValues>({
        defaultValues: {
            email: '',
            password: '',
        },
        mode: 'onBlur',
    });

    const onSubmit = async (data: FormValues) => {
        try {
            await auth.signIn(data.email, data.password);
        } catch (e: any) {
            alert.error(e.message);
            return;
        }
    };

    const petternEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <Box
                component='div'
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component='h1' variant='h3'>
                    CLAY.Di
                </Typography>
                <Typography component='h1' variant='h6'>
                    MANAGER
                </Typography>
                <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                    <ValidTextField
                        margin='normal'
                        fullWidth
                        name='email'
                        label='Email Address'
                        rules={{
                            required: '이메일 주소를 입력해 주세요.',
                            pattern: {
                                value: petternEmail,
                                message: '유효한 이메일 주소를 입력해 주세요.',
                            },
                        }}
                        control={control}
                    />
                    <ValidTextField
                        margin='normal'
                        fullWidth
                        name='password'
                        label='Password'
                        autoComplete='current-password'
                        rules={{ required: '비밀번호를 입력해 주세요.' }}
                        control={control}
                        type='password'
                    />

                    <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                        LOGIN
                    </Button>
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
}
