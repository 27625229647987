import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ViewPage from '~/components/view/ViewPage';
import { ApiContext } from '~/contexts/ApiContext';
import { ChannelType } from '~/types/ChannelType';
import ChannelInfo from './ChannelInfo';
import ChannelDetail from './ChannelDetail';

const Channel = () => {
    const api = useContext(ApiContext);
    const [channel, setChannel] = useState<ChannelType>({});
    const { channelId } = useParams();

    useEffect(() => {
        api.get(`/channels/${channelId}`).then((data) => {
            console.log(data);
            setChannel(data);
        });
    }, [channelId, api]);

    return (
        <ViewPage pageTitle='채널 정보'>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    {channel.channelId && <ChannelInfo channel={channel} />}
                </Grid>
                <Grid item xs={8}>
                    {channel.channelId && <ChannelDetail channel={channel} />}
                </Grid>
            </Grid>
        </ViewPage>
    );
};

export default Channel;
