import { Chip, Link, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { PageTitleProps } from '~/components/common/PageTitle';
import { ProfileImage } from '~/components/common/ProfileImage';
import { ThumbnailImage } from '~/components/common/ThumbnailImage';
import ListPage from '~/components/list/ListPage';
import ListPagination, { PaginationListType } from '~/components/list/ListPagination';
import TableListItems, { ListColumnType } from '~/components/list/TableListItems';
import ViewPage from '~/components/view/ViewPage';
import { AlertContext } from '~/contexts/AlertContext';
import { ApiContext } from '~/contexts/ApiContext';
import { ChannelType } from '~/types/ChannelType';
import { DateFormatter } from '~/utils/DateFormatter';

type Props = {
    channel: ChannelType;
};

const ChannelContents = ({ channel }: Props) => {
    const api = useContext(ApiContext);
    const alert = useContext(AlertContext);
    const [listData, setListData] = useState<PaginationListType>({});

    const [page, setPage] = useState(1);
    const [size] = useState(10);

    const handlePage = (page: number) => {
        setPage(page);
    };

    useEffect(() => {
        api.get(`/channels/${channel?.channelId}/contents`, { page: page, size: size })
            .then((data) => {
                console.log(data);
                data.content = data.content?.map((obj: any) => ({
                    ...obj,
                    id: obj.contentId,
                    contentLink: (
                        <Link underline='hover' href={'/posted-contents/' + obj.contentId}>
                            {obj.contentId}
                        </Link>
                    ),
                    contentInfo: (
                        <Stack direction='row' alignItems='center' spacing={1}>
                            <ThumbnailImage src={obj.url?.thumbnail} alt={obj.title} />
                            <Typography variant='body2'>{obj.title}</Typography>
                        </Stack>
                    ),
                    userInfo: (
                        <Stack direction='row' alignItems='center' spacing={0.5}>
                            <ProfileImage src={obj.user.url?.thumbnail} title={obj.user.nickname} />
                            <Typography variant='body2'>
                                <Link underline='hover' href={'/users/' + obj.user.channelId}>
                                    {obj.user.nickname}
                                </Link>
                            </Typography>
                        </Stack>
                    ),
                    status: (
                        <Chip
                            size='small'
                            label={obj.status}
                            color={
                                ({
                                    EXPOSED: 'success',
                                    HIDDEN: 'default',
                                    BANNED: 'warning',
                                }[obj.status as string] as 'success' | 'warning' | 'default') || 'default'
                            }
                        />
                    ),
                    createdAt: DateFormatter.toFormattedDate(obj.createdAt as string),
                }));
                setListData(data);
            })
            .catch((e: any) => {
                alert.error(e.message, -1);
            });
    }, [api, channel, page, size, setListData, alert]);

    const listColumn: ListColumnType[] = [
        { column: 'id', isHidden: true, idColumn: true },
        { column: 'contentLink', title: 'ID' },
        { column: 'contentInfo', title: '컨텐츠' },
        { column: 'userInfo', title: '게시자' },
        { column: 'hit', title: '조회수' },
        { column: 'commentCount', title: '댓글수' },
        { column: 'status', title: '상태' },
        { column: 'createdAt', title: '게시일' },
    ];

    const pageTitleProps: PageTitleProps = {
        component: 'h2',
        variant: 'subtitle1',
        color: 'default',
    };

    return (
        <ViewPage pageTitle='채널 컨텐츠' pageTitleProps={pageTitleProps}>
            {listData.totalElements !== undefined && listData.totalElements > 0 ? (
                <ListPage>
                    <ListPagination listData={listData} currentPage={page} handlePage={handlePage}>
                        {listData.content && (
                            <TableListItems items={listData.content} listColumn={listColumn}></TableListItems>
                        )}
                    </ListPagination>
                </ListPage>
            ) : (
                <Typography component='h2' variant='body2' textAlign='center' sx={{ marginTop: 2 }}>
                    게시된 컨텐츠가 없습니다.
                </Typography>
            )}
        </ViewPage>
    );
};

export default ChannelContents;
