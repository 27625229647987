import React, { createContext, ReactNode } from 'react';
import { useApi } from '~/hooks/useApi';

export const ApiContext = createContext({
    get:async(path:string, params?:any, callback?:Function):Promise<any>=>{}, 
    post:async(path:string, params?:any):Promise<any>=>{}, 
    delete:async(path:string):Promise<any>=>{}
});

export const ApiProvider = ({ children } : {children: ReactNode}) => {
    const api = useApi();
    return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};
