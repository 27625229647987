import { AppBar, Toolbar, Typography, Button, useTheme } from '@mui/material';
import colorConfigs from '~/configs/colorConfigs';
import sizeConfigs from '~/configs/sizeConfigs';
import { useSelector } from 'react-redux';
import { useContext } from 'react';
import { AuthContext } from '~/contexts/AuthContext';
import { ProfileImage } from '~/components/common/ProfileImage';

const Topbar = () => {
    const { signOut } = useContext(AuthContext);
    const manager = useSelector((state: any) => state.authState.manager || {});
    const theme = useTheme();

    return (
        <AppBar
            position='fixed'
            sx={{
                width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                minWidth: `calc(100% - ${sizeConfigs.sidebar.maxWidth})`,
                ml: sizeConfigs.sidebar.width,
                boxShadow: 'unset',
                backgroundColor: colorConfigs.topbar.bg,
                color: colorConfigs.topbar.color,
            }}>
            <Toolbar>
                <Typography variant='h6' sx={{ flexGrow: 1 }}>
                    MANAGER
                </Typography>
                <ProfileImage
                    alt={manager.nickname}
                    src={manager.url?.thumbnail}
                    sx={{ mr: 1 }}
                    title={manager.nickname}
                />
                <Typography variant='h6' sx={{ textAlign: 'right', fontSize: 14, marginRight: 3 }}>
                    {manager.nickname}
                </Typography>
                <Button
                    size='small'
                    variant='text'
                    color='error'
                    onClick={() => {
                        signOut();
                    }}>
                    LOGOUT
                </Button>
            </Toolbar>
        </AppBar>
    );
};

export default Topbar;
