import React, { useState } from 'react';
import { Card, Tabs, Tab, Box } from '@mui/material';
import { UserType } from '~/types/UserType';
import { useLocation } from 'react-router-dom';
import UserChannel from './UserChannel';
import UserContents from './UserBookmarkedContents';
import UserComment from './UserComment';
import UserReport from './UserReport';
import UserFollowedChannels from './UserFollowedChannels';
import UserBookmarkedContents from './UserBookmarkedContents';

type TabPanelProps = {
    children?: React.ReactNode;
    index: string;
    value: string;
};

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}>
            {value === index && (
                <Box component='div' sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

type Props = {
    user: UserType;
};

const UserDetail = (props: Props) => {
    const { hash } = useLocation();
    const [value, setValue] = useState(hash ? hash : '#channel');
    const user = props.user;
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <Card variant='outlined'>
            <Tabs value={value} onChange={handleChange} aria-label='wrapped label tabs example'>
                <Tab value='#channel' label='사용자 채널' href='#channel' />
                <Tab value='#posting' label='컨텐츠' href='#posting' />
                <Tab value='#comments' label='댓글' href='#comments' />
                <Tab value='#report' label='신고' href='#report' />
                <Tab value='#followedChannels' label='팔로우한 채널' href='#followedChannels' />
                <Tab value='#bookmarkedContents' label='북마크한 컨텐츠' href='#bookmarkedContents' />
            </Tabs>
            <TabPanel value={value} index={'#channel'}>
                <UserChannel user={user} subPage={true} />
            </TabPanel>
            <TabPanel value={value} index={'#posting'}>
                <UserContents user={user} />
            </TabPanel>
            <TabPanel value={value} index={'#comments'}>
                <UserComment user={user} />
            </TabPanel>
            <TabPanel value={value} index={'#report'}>
                <UserReport user={user} />
            </TabPanel>
            <TabPanel value={value} index={'#followedChannels'}>
                <UserFollowedChannels user={user} />
            </TabPanel>
            <TabPanel value={value} index={'#bookmarkedContents'}>
                <UserBookmarkedContents user={user} />
            </TabPanel>
        </Card>
    );
};

export default UserDetail;
