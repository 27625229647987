import { Alert, AlertColor, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';

type Props = {
    openAlert: boolean;
    message: string;
    severity?: AlertColor;
    onClose?: ()=>void;
}
const SnackbarAlert = (props:Props) => {
    const[openAlert, setOpenAlert] = useState(false);
    let alertMessage = props.message;

    useEffect(() => {
        setOpenAlert(props.openAlert);
    }, [props])

    const closeAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
        if(props.onClose !== undefined) {
            props.onClose();
        }
    };

    return (
        <Snackbar
            open={openAlert}
            autoHideDuration={1000}
            onClose={closeAlert}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={closeAlert} severity={props.severity ? props.severity : 'success'} sx={{ width: '100%' }}>
                {alertMessage}
            </Alert>
        </Snackbar>
    );
}

export default SnackbarAlert;