import { RouteType } from './config';
import {
    BasePageLayout,
    Users,
    User,
    Channels,
    Channel,
    UserDevices,
    Tips,
    TipEdit,
    TipAdd,
    Reports,
    CommentReports,
    PostedContents,
    PostedContent,
    ScannedProducts,
    ScannedProduct,
    ContentPost,
    PostedContentsDisplay,
} from '~/pages';
import { AccountCircleIcon, ViewInArIcon, GradingIcon, ReportIcon } from '~/assets/Icons';

const appRoutes: RouteType[] = [
    {
        element: <BasePageLayout />,
        state: 'users',
        sidebarProps: {
            displayText: '사용자',
            icon: <AccountCircleIcon />,
        },
        child: [
            {
                index: true,
                element: <Users />,
                state: 'users.list',
            },
            {
                path: '/users',
                element: <Users />,
                state: 'users.list',
                sidebarProps: {
                    displayText: '사용자 리스트',
                },
            },
            {
                path: '/users/device',
                element: <UserDevices />,
                state: 'users.device.list',
                sidebarProps: {
                    displayText: '사용자 디바이스',
                },
            },
            {
                path: '/channels',
                element: <Channels />,
                state: 'channel.list',
                sidebarProps: {
                    displayText: '채널 리스트',
                },
            },
        ],
    },
    {
        path: '/users/:userId',
        element: <User />,
        state: 'users.list',
    },
    {
        path: '/channels/:channelId',
        element: <Channel />,
        state: 'channel.list',
    },
    {
        element: <BasePageLayout />,
        state: 'contents',
        sidebarProps: {
            displayText: '3D 컨텐츠',
            icon: <ViewInArIcon />,
        },
        child: [
            {
                index: true,
                element: <PostedContents />,
                state: 'contents.list',
            },
            {
                path: 'posted-contents',
                element: <PostedContents />,
                state: 'contents.posted',
                sidebarProps: {
                    displayText: '게시된 3D',
                },
            },
            {
                path: 'posted-contents/display',
                element: <PostedContentsDisplay />,
                state: 'contents.posted.display',
                sidebarProps: {
                    displayText: '3D 컨텐츠 진열',
                },
            },
            {
                path: '/scanned-products',
                element: <ScannedProducts />,
                state: 'contents.scanned',
                sidebarProps: {
                    displayText: '스캔 제품',
                },
            },
        ],
    },
    {
        path: '/posted-contents/:contentId',
        element: <PostedContent />,
        state: 'contents.posted',
    },
    {
        path: '/contents/post',
        element: <ContentPost />,
        state: 'contents.posted',
    },
    {
        path: '/scanned-products/:productId',
        element: <ScannedProduct />,
        state: 'contents.scanned',
    },
    {
        element: <BasePageLayout />,
        state: 'tips',
        sidebarProps: {
            displayText: 'TIP',
            icon: <GradingIcon />,
        },
        child: [
            {
                index: true,
                element: <Tips />,
                state: 'tips.list',
            },
            {
                path: '/tips',
                element: <Tips />,
                state: 'tips.list',
                sidebarProps: {
                    displayText: 'Tip 리스트',
                },
            },
        ],
    },
    {
        path: '/tips/:tipId',
        element: <TipEdit />,
        state: 'tips.list',
    },
    {
        path: '/tips/add',
        element: <TipAdd />,
        state: 'tips.list',
    },
    {
        element: <BasePageLayout />,
        state: 'reports',
        sidebarProps: {
            displayText: '게시물 신고',
            icon: <ReportIcon />,
        },
        child: [
            {
                index: true,
                element: <Reports />,
                state: 'reports.list',
            },
            {
                path: '/reports',
                element: <Reports />,
                state: 'reports.list',
                sidebarProps: {
                    displayText: '컨텐츠 신고 리스트',
                },
            },
            {
                path: '/reports/comment',
                element: <CommentReports />,
                state: 'reports.comment.list',
                sidebarProps: {
                    displayText: '댓글 신고 리스트',
                },
            },
        ],
    },
];

export default appRoutes;
