import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import colorConfigs from "~/configs/colorConfigs";
import sizeConfigs from "~/configs/sizeConfigs";
import Sidebar from "~/components/common/Sidebar";
import Topbar from "~/components/common/Topbar";

const MainLayout = () => {
  return (
      <Box component='div' sx={{ display: 'flex' }}>
          <Topbar />
          <Box
              component='nav'
              sx={{
                  width: sizeConfigs.sidebar.width,
                  maxWidth: sizeConfigs.sidebar.maxWidth,
                  flexShrink: 0,
              }}>
              <Sidebar />
          </Box>
          <Box
              component='main'
              sx={{
                  flexGrow: 1,
                  p: 3,
                  width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                  minWidth: `calc(100% - ${sizeConfigs.sidebar.maxWidth})`,
                  minHeight: '100vh',
                  backgroundColor: colorConfigs.mainBg,
              }}>
              <Toolbar />
              <Outlet />
          </Box>
      </Box>
  );
};

export default MainLayout;