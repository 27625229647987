import { GlobalStylesProps, Stack, SxProps } from '@mui/material';
import React from 'react';
import PageTitle, { PageTitleProps } from '~/components/common/PageTitle';

type Props = {
    children?: React.ReactNode;
    pageTitle?: string;
    pageTitleProps?: PageTitleProps;
    pageActionButton?: React.ReactNode;
};

const ViewPage = (props: Props) => {
    return (
        <div>
            {(props.pageTitle || props.pageActionButton) && (
                <Stack justifyContent='space-between' direction='row' alignContent='center'>
                    {props.pageTitle && <PageTitle {...props.pageTitleProps}>{props.pageTitle}</PageTitle>}
                    {props.pageActionButton && props.pageActionButton}
                </Stack>
            )}
            {props.children}
        </div>
    );
};

export default ViewPage;
