import React, { useContext } from 'react';
import { ApiContext } from '~/contexts/ApiContext';
import ContentPostForm from './ContentPostForm';

const ContentPost = () => {
    const api = useContext(ApiContext);

    return <ContentPostForm></ContentPostForm>;
};

export default ContentPost;
