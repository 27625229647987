import React, { ReactNode } from 'react';
import { Select, SelectProps, SelectChangeEvent, MenuItem, Typography, InputLabel } from '@mui/material';
import { TControl } from '~/types/TControl';
import { FieldValues, useController } from 'react-hook-form';

export type ValidSelectItem = {
    label: ReactNode;
    value: string | number;
    selected?: boolean;
    disabled?: boolean;
    hidden?: boolean;
};

type ValidSelectProps<T> = {
    selectList: ValidSelectItem[];
    placeholder: string;
    onChange?: (event: SelectChangeEvent<T>) => void;
    multiple?: boolean;
};

type TProps<T extends FieldValues> = Omit<SelectProps, 'onChange' | 'placeholder'> & ValidSelectProps<T> & TControl<T>;

function ValidSelect<T extends FieldValues>(props: TProps<T>) {
    const {
        name,
        rules,
        control,
        selectList,
        placeholder,
        onChange: propsOnChange,
        multiple,
        renderValue: propsRenderValue,
    } = props;
    const {
        field: { value, onChange, onBlur },
        fieldState: { isDirty, isTouched, error },
    } = useController({ name, rules, control });

    const handleChange = (event: SelectChangeEvent<T>) => {
        try {
            if (propsOnChange) {
                propsOnChange(event);
            }
            onChange(event);
        } catch (e: any) {
            console.log(e);
        }
    };

    const renderValue = () => {
        if (propsRenderValue) {
            return propsRenderValue(value);
        } else {
            return value ? selectList.find((item) => item.value === value)?.label : placeholder;
        }
    };

    return (
        <>
            <InputLabel id={`label-${name}`}>{placeholder}</InputLabel>
            <Select
                value={value}
                renderValue={renderValue}
                onChange={handleChange}
                onBlur={onBlur}
                labelId={`label-${name}`}
                label={placeholder}
                error={error ? true : false}
                multiple={multiple ?? false}>
                {selectList.map(({ label, value, disabled }, index) => (
                    <MenuItem key={index} value={value} disabled={disabled ?? false}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
            <Typography variant='caption' color='error'>
                {error ? error.message : ''}
            </Typography>
        </>
    );
}

export default ValidSelect;
