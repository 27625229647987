import React from 'react';
import PageTitle from '~/components/common/PageTitle';
import { Stack } from '@mui/material';

type Props = {
    children?: React.ReactNode;
    pageTitle?: string;
}
const ListPage = (props : Props) => {
    return (
        <>
            {props.pageTitle && <PageTitle>{props.pageTitle}</PageTitle>}
            {props.children}
        </>
    );
}

export default ListPage;