import { useContext, useEffect, useState } from 'react';
import { ApiContext } from '~/contexts/ApiContext';
import ListPage from '~/components/list/ListPage';
import TableListItems, { ListColumnType } from '~/components/list/TableListItems';
import ListPagination, { PaginationListType } from '~/components/list/ListPagination';
import { useQueryParams, NumberParam, withDefault, ObjectParam } from 'use-query-params';
import { Chip, Link, Stack, Typography } from '@mui/material';
import { ProfileImage } from '~/components/common/ProfileImage';
import { DateFormatter } from '~/utils/DateFormatter';
import ListSearchBar from '~/components/common/ListSearchBar';

const Channels = () => {
    const api = useContext(ApiContext);
    const [search, setSearch] = useQueryParams({
        page: withDefault(NumberParam, 1),
        size: withDefault(NumberParam, 10),
        filters: withDefault(ObjectParam, null),
    });
    const [searchValue, setSearchValue] = useState<string>('');
    const handleSearch = async (e: any) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        setSearch({ filters: { search: formData.get('search_value') as string } }, 'push');
        setSearchValue(formData.get('search_value') as string);
    };
    const [listData, setListData] = useState<PaginationListType>({});

    useEffect(() => {
        api.get('/channels', { page: search.page, size: search.size, filters: { search: searchValue } }).then(
            (data) => {
                data.content = data.content.map((obj: any) => ({
                    ...obj,
                    id: obj.channelId,
                    channelLink: (
                        <Link underline='hover' href={'/channels/' + obj.channelId}>
                            {obj.channelId}
                        </Link>
                    ),
                    channelInfo: (
                        <Stack direction='row' spacing={0.5} alignItems='center'>
                            <ProfileImage src={obj.url?.thumbnail} title={obj.name} />
                            <Typography variant='body2'>{obj.name}</Typography>
                        </Stack>
                    ),
                    status: (
                        <Chip
                            size='small'
                            label={obj.status}
                            color={
                                ({
                                    ENABLED: 'success',
                                    DISABLED: 'default',
                                    BANNED: 'warning',
                                }[obj.status as string] as 'success' | 'warning' | 'default') || 'default'
                            }
                        />
                    ),
                    openedAt: (
                        <div>
                            <Typography component='div' variant='caption'>
                                {DateFormatter.toFormattedDate(obj.createdAt as string)}
                            </Typography>
                        </div>
                    ),
                }));
                setListData(data);
            }
        );
    }, [api, search.page, search.size, searchValue]);

    const listColumn: ListColumnType[] = [
        { column: 'id', isHidden: true, idColumn: true },
        { column: 'channelLink', title: 'ID' },
        { column: 'channelInfo', title: '채널' },
        { column: 'description', title: '소개' },
        { column: 'status', title: '상태' },
        { column: 'followerCount', title: '팔로워 수' },
        { column: 'openedAt', title: '생성일' },
    ];

    return (
        <ListPage pageTitle='사용자 채널 리스트'>
            <ListSearchBar handleSubmit={handleSearch} placeHolder='채널명을 입력해주세요' />
            <ListPagination listData={listData} currentPage={search.page}>
                {listData.content && <TableListItems items={listData.content} listColumn={listColumn} />}
            </ListPagination>
        </ListPage>
    );
};

export default Channels;
