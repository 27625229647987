import React, { useState } from 'react';
import MDEditor, { MDEditorProps } from '@uiw/react-md-editor';
import { TControl } from '~/types/TControl';
import { FieldValues, useController } from 'react-hook-form';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type TProps<T extends FieldValues> = MDEditorProps & TControl<T>;

function ValidMDEditor<T extends FieldValues>({ name, rules, control, ...props }: TProps<T>) {
    const {
        field: { value, onChange },
        fieldState: { isDirty, isTouched, error },
    } = useController({ name, rules, control });
    const theme = useTheme();

    const [hasFocus, setHasFocus] = useState<boolean>(false);
    console.log(hasFocus);

    return (
        <>
            <div data-color-mode='light'>
                <MDEditor
                    height={500}
                    value={value}
                    onChange={onChange}
                    textareaProps={{ name: name, onChange: onChange, value: value }}
                    style={{
                        border: '1px solid transparent',
                        borderWidth: hasFocus ? '2px' : '1px',
                        borderColor: error
                            ? theme.palette.error.main
                            : hasFocus
                            ? theme.palette.primary.main
                            : 'transparent',
                    }}
                    onFocus={() => setHasFocus(true)}
                    onBlur={() => setHasFocus(false)}
                    highlightEnable={true}
                />
            </div>
            <Typography variant='caption' color='error'>
                {error ? error.message : ''}
            </Typography>
        </>
        /*
        <>
            <TextField value={value} onChange={onChange} error={error ? true : false} {...props} />
            <Typography variant='caption' color='error'>
                {error ? error.message : ''}
            </Typography>
        </>
        */
    );
}

export default ValidMDEditor;
