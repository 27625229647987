import { Avatar, Drawer, List, Stack, Toolbar, Typography } from '@mui/material';
import assets from '~/assets/index';
import colorConfigs from '~/configs/colorConfigs';
import sizeConfigs from '~/configs/sizeConfigs';
import appRoutes from '~/routes/appRoutes';
import SidebarItem from '~/components/common/SidebarItem';
import SidebarItemCollapse from '~/components/common/SidebarItemCollapse';

const Sidebar = () => {
    return (
        <Drawer
            variant='permanent'
            sx={{
                width: sizeConfigs.sidebar.width,
                maxWidth: sizeConfigs.sidebar.maxWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: sizeConfigs.sidebar.width,
                    maxWidth: sizeConfigs.sidebar.maxWidth,
                    boxSizing: 'border-box',
                    borderRight: '0px',
                    backgroundColor: colorConfigs.sidebar.bg,
                    color: colorConfigs.sidebar.color,
                },
            }}>
            <List disablePadding>
                <Toolbar>
                    <Stack sx={{ width: '100%' }} direction='row' justifyContent='center'>
                        <Typography variant='h6'>CLAY.Di</Typography>
                    </Stack>
                </Toolbar>
                {appRoutes.map((route, index) =>
                    route.sidebarProps ? (
                        route.child ? (
                            <SidebarItemCollapse item={route} key={index} />
                        ) : (
                            <SidebarItem item={route} key={index} />
                        )
                    ) : null
                )}
            </List>
        </Drawer>
    );
};

export default Sidebar;
