import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

export type ItemType = {
    id: number;
    [key: string]: string | number | boolean | [] | React.ReactNode;
};

export type ListColumnType = {
    column: string;
    title?: string;
    isHidden? : boolean;
    href?: string;
    onClick?: () => void;
    idColumn?: boolean;
};

type Props = {
    items: ItemType[];
    listColumn: ListColumnType[];
};

const TableListItems = ({ items, listColumn }: Props) => {
    var idColumn = '';
    listColumn.forEach((column) => {
        if (column.idColumn === true) {
            idColumn = column.column;
        }
    });

    return (
        <Table size='small'>
            <TableHead>
                <TableRow>
                    {listColumn.map(
                        (column, index) => column.isHidden !== true && <TableCell key={index}>{column.title}</TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {items.map((item) => (
                    <TableListRow
                        item={item}
                        listColumn={listColumn}
                        key={item[idColumn] as string}
                        id={item[idColumn] as string}
                    />
                ))}
            </TableBody>
        </Table>
    );
};

type TableListRowProps = {
    item: ItemType;
    listColumn: ListColumnType[];
    id: string | number;
};

const TableListRow = ({ item, listColumn, id }: TableListRowProps) => {
    return (
        <TableRow>
            {listColumn.map(
                (column, index) =>
                    column.isHidden !== true && (
                        <TableListCell cellItem={item[column.column]} column={column} key={id + '_' + column.column} />
                    )
            )}
        </TableRow>
    );
};

type TableListCellProps = {
    cellItem: any;
    column: ListColumnType;
};

const TableListCell = ({ cellItem, column }: TableListCellProps) => {
    var children = cellItem;
    var options: any = {};
    if (column.onClick) options.onClick = column.onClick;
    return React.createElement(TableCell, options, children);
};

export default TableListItems;
