import React from 'react';
import { Stack } from '@mui/material';
import { UserType } from '~/types/UserType';
import UserReportContent from './UserReportContent';
import UserReportComment from './UserReportComment';

type Props = {
    user?: UserType;
};

const UserReport = (props: Props) => {
    const user = props.user;
    return (
        <Stack spacing={5}>
            {user && <UserReportContent user={user} />}
            {user && <UserReportComment user={user} />}
        </Stack>
    );
};

export default UserReport;
