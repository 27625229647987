import React, { useState } from 'react';
import { Card, Tabs, Tab, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ChannelType } from '~/types/ChannelType';
import ChannelContents from './ChannelContents';
import ChannelFollowers from './ChannelFollowers';
import ChannelMembers from './ChannelMembers';

type TabPanelProps = {
    children?: React.ReactNode;
    index: string;
    value: string;
};

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}>
            {value === index && (
                <Box component='div' sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

type Props = {
    channel: ChannelType;
};

const ChannelDetail = ({ channel }: Props) => {
    const { hash } = useLocation();
    const [value, setValue] = useState(hash ? hash : '#contents');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <Card variant='outlined'>
            <Tabs value={value} onChange={handleChange} aria-label='wrapped label tabs example'>
                <Tab value='#contents' label='채널 컨텐츠' href='#contents' />
                <Tab value='#followers' label='채널 팔로워' href='#followers' />
                <Tab value='#members' label='채널 멤버' href='#members' />
            </Tabs>
            <TabPanel value={value} index={'#contents'}>
                <ChannelContents channel={channel} />
            </TabPanel>
            <TabPanel value={value} index={'#followers'}>
                <ChannelFollowers channel={channel} />
            </TabPanel>
            <TabPanel value={value} index={'#members'}>
                <ChannelMembers channel={channel} />
            </TabPanel>
        </Card>
    );
};

export default ChannelDetail;
