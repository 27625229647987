export const JsonSerializer = {
    serialize(obj: any) {
        return JSON.stringify(obj, (key, value) => {
            if (value !== null) return value;
        });
    },
    deserialize(str: string) {
        return JSON.parse(str);
    },
};
