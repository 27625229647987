import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainLayout from './components/layout/MainLayout';
import { routes } from './routes';
import useSelectorTyped from '~/hooks/useSelectorTyped';
import Login from '~/pages/auth/Login';
import { ApiProvider } from '~/contexts/ApiContext';
import { AuthProvider } from '~/contexts/AuthContext';
import { ThemeProvider } from '@mui/material/styles';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { AlertProvider } from './contexts/AlertContext';
import { ActionDialogProvider } from './contexts/ActionDialogContext';
import { theme } from '~/theme';

const App = () => {
    const { authentication } = useSelectorTyped((state) => state.authState);

    if (!authentication) {
        return (
            <ThemeProvider theme={theme}>
                <ApiProvider>
                    <AuthProvider>
                        <BrowserRouter>
                            <AlertProvider>
                                <Login />
                            </AlertProvider>
                        </BrowserRouter>
                    </AuthProvider>
                </ApiProvider>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <ApiProvider>
                <AuthProvider>
                    <BrowserRouter>
                        <AlertProvider>
                            <ActionDialogProvider>
                                <QueryParamProvider adapter={ReactRouter6Adapter}>
                                    <Routes>
                                        <Route path="/" element={<MainLayout />}>
                                            {routes}
                                        </Route>
                                    </Routes>
                                </QueryParamProvider>
                            </ActionDialogProvider>
                        </AlertProvider>
                    </BrowserRouter>
                </AuthProvider>
            </ApiProvider>
        </ThemeProvider>
    );
};

export default App;
