import { DialogContentText } from '@mui/material';
import React, { createContext, useState } from 'react';
import ActionDialog from '~/components/common/ActionDialog';

export const ActionDialogContext = createContext({
    action: async(title?: string, content?: React.ReactNode | string, actionButton?: React.ReactNode)=>{},
    closeDialog: async() => {},
});

export const ActionDialogProvider = ({ children } : {children: React.ReactNode}) => {
    const [oepnDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState<string|undefined>('');
    const [dialogContent, setDialogContent] = useState<React.ReactNode|undefined>(<></>);
    const [actionButton, setActionButton] = useState<React.ReactNode|undefined>();

    const actions = {
        action: async(title?: string, content?: React.ReactNode | string, actionButton?: React.ReactNode)=>{
            setOpenDialog(true);
            setDialogTitle(title);
            if(typeof content == 'string') {
                setDialogContent(<DialogContentText>{content}</DialogContentText>);
            }
            else {
                setDialogContent(content);
            }

            if(actionButton !== undefined) {
                
                setActionButton(actionButton)
            }
        },
        closeDialog: async() => {
            setOpenDialog(false);
        }
    }

    return (
        <ActionDialogContext.Provider value={actions}>
            {children}
            <ActionDialog
                open={oepnDialog}
                title={dialogTitle}
                content={dialogContent}
                actionButton={actionButton}
                onClose={() => {
                    setOpenDialog(false);
                }}
            />
        </ActionDialogContext.Provider>
    );
};
