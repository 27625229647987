import { AlertColor } from '@mui/material';
import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SnackbarAlert from '~/components/common/SnackbarAlert';

export const AlertContext = createContext({
    success:async(message: string, naviagationStep?: number|string)=>{}, 
    info:async(message: string, naviagationStep?: number|string)=>{}, 
    warning:async(message: string, naviagationStep?: number|string)=>{},
    error:async(message: string, naviagationStep?: number|string)=>{},
});

export const AlertProvider = ({ children } : {children: React.ReactNode}) => {
    //const api = useApi();
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [severity, setSeverity] = useState<AlertColor>('success');
    const [step, setStep] = useState<undefined | number | string>(undefined);
    const navigate = useNavigate();

    const alertSetting = (severity: AlertColor, message: string, step?: number | string) => {
        setSeverity(severity);
        setOpenAlert(true);
        setAlertMessage(message);
        setStep(step);
    };

    const alert = {
        success: async (message: string, navigationStep?: number | string) => {
            alertSetting('success', message, navigationStep);
        },
        info: async (message: string, navigationStep?: number | string) => {
            alertSetting('info', message, navigationStep);
        },
        warning: async (message: string, navigationStep?: number | string) => {
            alertSetting('warning', message, navigationStep);
        },
        error: async (message: string, navigationStep?: number | string) => {
            alertSetting('error', message, navigationStep);
        },
    };

    return (
        <AlertContext.Provider value={alert}>
            {children}
            <SnackbarAlert
                openAlert={openAlert}
                message={alertMessage}
                severity={severity}
                onClose={() => {
                    setOpenAlert(false);
                    if (step !== undefined) {
                        if (typeof step == 'string') {
                            navigate(step);
                        }
                        if (typeof step == 'number') {
                            navigate(step);
                        }
                    }
                }}
            />
        </AlertContext.Provider>
    );
};
