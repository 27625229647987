import { Card, CardContent, Chip, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { DateFormatter } from '~/utils/DateFormatter';
import { ProfileImage } from '~/components/common/ProfileImage';
import { ProductType } from '~/types/ProductType';

type Props = {
    product: ProductType;
};

const ProductInfo = ({ product }: Props) => {
    console.log(product);
    return (
        <Card variant='outlined'>
            <Stack
                direction='column'
                spacing={2}
                divider={<Divider variant='inset' orientation='horizontal' flexItem />}>
                <CardContent sx={{ flex: 1 }}>
                    <Stack alignItems='center' mt={3} spacing={1}>
                        <ProfileImage
                            variant='square'
                            src={product.url?.thumbnail}
                            sx={{ width: 200, height: 200 }}
                            title={product.name}
                        />

                        <Stack direction='row' spacing={1}>
                            {product.isEnabled === true ? (
                                <Chip size='small' color='success' label='ENABLED' />
                            ) : (
                                <Chip size='small' color='error' label='DISABLED' />
                            )}
                        </Stack>
                        <Typography variant='body1'>{product.name}</Typography>
                    </Stack>
                </CardContent>
                <CardContent sx={{ flex: 1 }}>
                    <div>
                        <Typography component='h2' variant='caption'>
                            렌더링 S/W
                        </Typography>
                        <Typography component='div' variant='body2'>
                            {product.renderStrategy}
                        </Typography>
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <Typography component='h2' variant='caption'>
                            렌더링 상태
                        </Typography>
                        <Typography component='div' variant='body2'>
                            <Chip
                                size='small'
                                label={product.status}
                                color={
                                    ({
                                        COMPLETE: 'success',
                                        PENDING: 'secondary',
                                        REQUEST: 'info',
                                        WORKING: 'primary',
                                        IN_QUEUE: 'warning',
                                        FAILURE: 'error',
                                    }[product.status as string] as
                                        | 'success'
                                        | 'warning'
                                        | 'secondary'
                                        | 'info'
                                        | 'primary'
                                        | 'error'
                                        | 'default') || 'default'
                                }
                            />
                        </Typography>
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <Typography component='h2' variant='caption'>
                            Barcode
                        </Typography>
                        <Typography component='div' variant='body2'>
                            {product.barcode}
                        </Typography>
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <Typography component='h2' variant='caption'>
                            ImageFingerprint
                        </Typography>
                        <Typography component='div' variant='body2'>
                            {product.imageFingerprint}
                        </Typography>
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <Typography component='h2' variant='caption'>
                            스캔일
                        </Typography>
                        <Typography variant='body2'>
                            {DateFormatter.toFormattedDate(product.createdAt as string)}
                        </Typography>
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <Typography component='h2' variant='caption'>
                            상태 변경일
                        </Typography>
                        <Typography variant='body2'>
                            {DateFormatter.toFormattedDate(product.updatedAt as string)}
                        </Typography>
                    </div>
                </CardContent>
            </Stack>
        </Card>
    );
};

export default ProductInfo;
