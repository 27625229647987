import { useContext, useEffect, useState } from 'react';
import { ApiContext } from '~/contexts/ApiContext';
import ListPage from '~/components/list/ListPage';
import TableListItems, { ListColumnType } from '~/components/list/TableListItems';
import ListPagination, { PaginationListType } from '~/components/list/ListPagination';
import { useQueryParams, NumberParam, withDefault, StringParam, ObjectParam } from 'use-query-params';
import { Chip, Link, Stack, Typography } from '@mui/material';
import { CommonUtil } from '~/utils/CommonUtil';
import { AlertContext } from '~/contexts/AlertContext';
import { DateFormatter } from '~/utils/DateFormatter';
import { ProfileImage } from '~/components/common/ProfileImage';

import ListSearchBar from '~/components/common/ListSearchBar';

const Users = () => {
    const api = useContext(ApiContext);
    const [listData, setListData] = useState<PaginationListType>({});
    const [searchValue, setSearchValue] = useState<string>('');
    const [search, setSearch] = useQueryParams({
        page: withDefault(NumberParam, 1),
        size: withDefault(NumberParam, 10),
        sort: withDefault(StringParam, 'userId,desc'),
        filters: withDefault(ObjectParam, null),
    });

    const alert = useContext(AlertContext);
    const handleSearch = async (e: any) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        setSearch({ filters: { search: formData.get('search_value') as string } }, 'push');
        setSearchValue(formData.get('search_value') as string);
    };

    useEffect(() => {
        api.get('/users', { page: search.page, size: search.size, sort: search.sort, filters: { search: searchValue } })
            .then((data) => {
                data.content = data.content?.map((obj: any) => ({
                    ...obj,
                    id: obj.userId,
                    userLInk: (
                        <Link underline='hover' href={`/users/${obj.userId}`}>
                            {obj.userId}
                        </Link>
                    ),
                    userInfo: (
                        <Stack direction='row' spacing={0.5} alignItems='center'>
                            <ProfileImage src={obj.url?.thumbnail} title={obj.nickname} />
                            <Typography variant='body2'>{obj.nickname}</Typography>
                        </Stack>
                    ),
                    role: (
                        <Chip
                            size='small'
                            label={obj.role}
                            color={
                                ({
                                    USER: 'info',
                                    ADMIN: 'warning',
                                }[obj.role as string] as 'warning' | 'info') || 'default'
                            }
                        />
                    ),
                    socialPlatform: (
                        <Chip
                            size='small'
                            label={obj.socialPlatform ? obj.socialPlatform : 'EMAIL'}
                            sx={{
                                backgroundColor: CommonUtil.stringToColor(
                                    obj.socialPlatform ? obj.socialPlatform : 'EMAIL'
                                ),
                                color: '#ffffff',
                            }}
                        />
                    ),
                    joinedAt: (
                        <div>
                            <Typography component='div' variant='caption'>
                                {DateFormatter.toFormattedDate(obj.createdAt as string)}
                            </Typography>
                        </div>
                    ),
                    lastLoggedAt: (
                        <div>
                            <Typography component='div' variant='caption'>
                                {DateFormatter.toFormattedDate(obj.loggedAt as string)}
                            </Typography>
                        </div>
                    ),
                }));
                setListData(data);
            })
            .catch((e: any) => {
                alert.error(e.message);
            });
    }, [api, alert, search.page, search.size, search.sort, searchValue]);

    const listColumn: ListColumnType[] = [
        { column: 'id', isHidden: true, idColumn: true },
        { column: 'userLInk', title: 'User ID' },
        { column: 'userInfo', title: '사용자' },
        { column: 'email', title: 'E-mail' },
        { column: 'role', title: 'Role' },
        { column: 'socialPlatform', title: '가입유형' },
        { column: 'joinedAt', title: '가입일' },
        { column: 'lastLoggedAt', title: '마지막 로그인' },
    ];

    return (
        <ListPage pageTitle='사용자 리스트'>
            <ListSearchBar handleSubmit={handleSearch} placeHolder='사용자 닉네임을 입력해주세요' />
            <ListPagination listData={listData} currentPage={search.page}>
                {listData.content && <TableListItems items={listData.content} listColumn={listColumn}></TableListItems>}
            </ListPagination>
        </ListPage>
    );
};

export default Users;
