import React, { createContext, ReactNode } from 'react';
import { useAuth } from '~/hooks/useAuth';

export const AuthContext = createContext({
    signIn:async(email:string, password:string):Promise<any>=>{}, 
    signOut:async():Promise<any>=>{}, 
});

export const AuthProvider = ({ children } : {children: ReactNode}) => {
    const auth = useAuth();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
