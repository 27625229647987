import { useContext, useEffect, useState } from 'react';
import { ApiContext } from '~/contexts/ApiContext';
import ListPage from '~/components/list/ListPage';
import TableListItems, { ListColumnType } from '~/components/list/TableListItems';
import ListPagination, { PaginationListType } from '~/components/list/ListPagination';
import { useQueryParams, NumberParam, withDefault, StringParam, ObjectParam } from 'use-query-params';
import { Input, Link, Stack, TextField, Typography } from '@mui/material';
import { AlertContext } from '~/contexts/AlertContext';
import { DateFormatter } from '~/utils/DateFormatter';
import { ProfileImage } from '~/components/common/ProfileImage';
import { ThumbnailImage } from '~/components/common/ThumbnailImage';
import ListSearchBar from '~/components/common/ListSearchBar';
import { EditText } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import { EditIcon } from '~/assets/Icons';

const PostedContentsDisplay = () => {
    const api = useContext(ApiContext);
    const [listData, setListData] = useState<PaginationListType>({});
    const [priority, setPriority] = useState(0);
    const [search, setSearch] = useQueryParams({
        page: withDefault(NumberParam, 1),
        size: withDefault(NumberParam, 10),
        sort: withDefault(StringParam, 'favoriteDisplayScore,DESC'),
        filters: withDefault(ObjectParam, null),
    });
    const [searchValue, setSearchValue] = useState<string>('');
    const handleSearch = async (e: any) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        setSearch({ filters: { search: formData.get('search_value') as string } }, 'push');
        setSearchValue(formData.get('search_value') as string);
    };
    const alert = useContext(AlertContext);

    const handleSave = ({ name, value, previousValue }: any) => {
        const path = `/posted-contents/${name}`;

        if (value !== previousValue) {
            api.post(path, { display_additional_score: value })
                .then((data) => {
                    setPriority(priority + 1);
                })
                .catch((e) => {
                    alert.error(e.message);
                });
        }
    };

    useEffect(() => {
        api.get('/posted-contents', {
            page: search.page,
            size: search.size,
            sort: search.sort,
            filters: { search: searchValue },
        })
            .then((data) => {
                console.log(data);
                data.content = data.content?.map((obj: any) => ({
                    ...obj,
                    id: obj.contentId,
                    contentLink: (
                        <Link underline='hover' href={`/posted-contents/${obj.contentId}`}>
                            {obj.contentId}
                        </Link>
                    ),
                    contentInfo: (
                        <Stack direction='row' alignItems='center' spacing={1}>
                            <ThumbnailImage src={obj.url?.thumbnail} alt={obj.title} />
                            <Stack direction='column'>
                                <Typography variant='body2'>{obj.title}</Typography>
                                <Typography variant='body2'>
                                    <Link underline='hover' href={`/channels/${obj.channel.channelId}`}>
                                        @{obj.channel.name}
                                    </Link>
                                </Typography>
                            </Stack>
                        </Stack>
                    ),
                    channelInfo: (
                        <Stack direction='row' alignItems='center' spacing={0.5}>
                            <ProfileImage src={obj.channel.url?.thumbnail} title={obj.channel.name} />
                            <Typography variant='body2'>
                                <Link underline='hover' href={`/channels/${obj.channel.channelId}`}>
                                    {obj.channel.name}
                                </Link>
                            </Typography>
                        </Stack>
                    ),
                    displayAdditional: (
                        <EditText
                            type='number'
                            inline={true}
                            showEditButton={true}
                            defaultValue={`${obj.displayAdditionalScore}`}
                            editButtonProps={{
                                style: {
                                    backgroundColor: 'transparent',
                                    padding: 0,
                                },
                            }}
                            editButtonContent={
                                <EditIcon fontSize='small' color='primary' sx={{ height: '16px', padding: 0 }} />
                            }
                            style={{ maxWidth: '60px' }}
                            name={`${obj.contentId}/display-additional-score`}
                            onSave={handleSave}
                        />
                    ),
                    changedAt: (
                        <div>
                            <Typography component='div' variant='caption'>
                                {DateFormatter.toFormattedDate(obj.createdAt as string)}
                            </Typography>
                            <Typography component='div' variant='caption'>
                                {DateFormatter.toFormattedDate(obj.updatedAt as string)}
                            </Typography>
                        </div>
                    ),
                }));
                setListData(data);
            })
            .catch((e: any) => {
                alert.error(e.message);
            });
    }, [api, search.page, search.size, search.sort, searchValue, alert, priority]);

    const listColumn: ListColumnType[] = [
        { column: 'id', isHidden: true, idColumn: true },
        { column: 'contentLink', title: 'ID' },
        { column: 'contentInfo', title: '컨텐츠' },
        { column: 'hit', title: '조회수' },
        { column: 'favoriteCount', title: '좋아요' },
        { column: 'commentCount', title: '댓글수' },
        { column: 'favoriteDisplayScore', title: '진열점수' },
        { column: 'displayAdditional', title: '진열추가 점수' },
        { column: 'changedAt', title: '게시일 / 수정일' },
    ];

    return (
        <ListPage pageTitle='인기순 리스트 진열 관리'>
            <ListSearchBar handleSubmit={handleSearch} placeHolder='컨텐츠 이름을 입력해주세요' />
            <ListPagination listData={listData} currentPage={search.page}>
                {listData.content && <TableListItems items={listData.content} listColumn={listColumn}></TableListItems>}
            </ListPagination>
            {/*
            <ListPagination
                listData={listData}
                currentPage={search.page}
                buttonNodes={
                    <Button variant='outlined' onClick={() => navigate('/contents/post')}>
                        등록
                    </Button>
                }>
                {listData.content && <TableListItems items={listData.content} listColumn={listColumn}></TableListItems>}
            </ListPagination>
            */}
        </ListPage>
    );
};

export default PostedContentsDisplay;
