import React, { useEffect, useState } from 'react';
import { Pagination, PaginationItem, Stack, Typography, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ItemType } from '~/components/list/TableListItems';
import { useSearchParams } from 'react-router-dom'; 
import PageTitle from '../common/PageTitle';

export type PaginationListType = {
    content?: ItemType[];
    size?: number;
    number?: number;
    totalPages?: number;
    totalElements?: number;
    [key: string]: string | number | boolean | ItemType[] | undefined;
}

type Props = {
    children?: React.ReactNode;
    listData: PaginationListType;
    currentPage: number;
    buttonNodes?: React.ReactNode;
    handlePage?: (page: number) => void;
};

const ListPagination = ({ listData, children, currentPage, buttonNodes, handlePage }: Props) => {
    const [page, setPage] = useState(currentPage);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get('page') !== null) {
            setPage(Number(searchParams.get('page')));
        }
    }, [searchParams]);

    if (listData === null) {
        return <></>;
    }

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        if (handlePage !== undefined) {
            handlePage(value);
        } else {
            searchParams.set('page', value.toString());
            setSearchParams(searchParams);
        }
        setPage(value);
    };

    return (
        <>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Typography component='h1' variant='subtitle1' color='default' ml={2}>
                        TOTAL : {listData.totalElements}
                    </Typography>
                </Grid>
            </Grid>
            {children}
            <Grid container spacing={2} mt={1}>
                <Grid item xs={8}>
                    <Stack alignItems='flex-start' mr={2}>
                        <Pagination count={listData.totalPages} page={page} onChange={handleChange} />
                    </Stack>
                </Grid>
                {buttonNodes && (
                    <Grid item xs={4} sx={{ textAlign: 'right' }}>
                        {buttonNodes}
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default ListPagination;