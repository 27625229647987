import { combineReducers } from '@reduxjs/toolkit';
import appStateSlice from '~/stores/features/appStateSlice';
import authStateSlice from '~/stores/features/authStateSlice';
import machineIdStateSlice from '~/stores/features/machineIdStateSlice';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: ['authState'],
};

const authPersistConfig = {
    key: 'auth',
    storage: sessionStorage,
};

const rootReducer = combineReducers({
    appState: appStateSlice,
    machineIdState: machineIdStateSlice,
    authState: persistReducer(authPersistConfig, authStateSlice),
});

export default persistReducer(persistConfig, rootReducer);
