import { Card, CardContent, Chip, Divider, Link, Paper, Stack, Typography } from '@mui/material';
import React from 'react';

import { BookmarkAddIcon, PageviewIcon, FavoriteIcon } from '~/assets/Icons';
import { DateFormatter } from '~/utils/DateFormatter';
import { ProfileImage } from '~/components/common/ProfileImage';
import SmallIconWithText from '~/components/common/SmallIconWithText';
import { ContentType } from '~/types/ContentType';

type Props = {
    content: ContentType;
};

const ContentInfo = ({ content }: Props) => {
    console.log(content);
    return (
        <Paper variant='outlined' square>
            <Stack
                direction='column'
                spacing={2}
                divider={<Divider variant='inset' orientation='horizontal' flexItem />}>
                <CardContent sx={{ flex: 1 }}>
                    <Stack alignItems='center' mt={3} spacing={1}>
                        <ProfileImage
                            variant='square'
                            src={content.url?.thumbnail}
                            sx={{ width: 200, height: 200 }}
                            title={content.title}
                        />
                        <Stack direction='row' spacing={3}>
                            <SmallIconWithText
                                icon={<PageviewIcon sx={{ fontSize: 15 }} />}
                                title='조회수'
                                value={content.hit}
                            />
                            <SmallIconWithText
                                icon={<FavoriteIcon sx={{ fontSize: 15 }} />}
                                title='좋아요수'
                                value={content.favoriteCount}
                            />
                            <SmallIconWithText
                                icon={<BookmarkAddIcon sx={{ fontSize: 15 }} />}
                                title='즐겨찾기'
                                value={content.bookmarkCount}
                            />
                        </Stack>
                        <Stack direction='row' spacing={1}>
                            {content.categories?.map((category: any) => (
                                <Chip key={category.categoryId} size='small' color='info' label={category.name} />
                            ))}
                        </Stack>
                        <Typography variant='body1'>{content.title}</Typography>
                        <Typography variant='body2'>{content.description}</Typography>
                    </Stack>
                </CardContent>
                <CardContent sx={{ flex: 1 }}>
                    <div>
                        <Typography component='h2' variant='caption'>
                            스캔 제품 ID
                        </Typography>
                        <Typography component='div' variant='body2'>
                            {content.productId}
                        </Typography>
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <Typography component='h2' variant='caption'>
                            게시 채널
                        </Typography>
                        <Typography component='div' variant='body2'>
                            <Stack direction='row' spacing={0.5} alignItems='center'>
                                <ProfileImage src={content.channel?.url?.thumbnail} title={content.channel?.name} />
                                <Typography variant='body2'>
                                    <Link href={`/channels/${content.channel?.channelId}`}>
                                        {content.channel?.name}
                                    </Link>
                                </Typography>
                            </Stack>
                        </Typography>
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <Typography component='h2' variant='caption'>
                            게시자
                        </Typography>
                        <Typography component='div' variant='body2'>
                            <Stack direction='row' spacing={0.5} alignItems='center'>
                                <ProfileImage src={content.user?.url?.thumbnail} title={content.user?.nickname} />
                                <Typography variant='body2'>
                                    <Link href={`/users/${content.user?.userId}`}>{content.user?.nickname}</Link>
                                </Typography>
                            </Stack>
                        </Typography>
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <Typography component='h2' variant='caption'>
                            게시 상태
                        </Typography>
                        <Typography component='div' variant='body2'>
                            <Chip
                                size='small'
                                color={
                                    ({
                                        EXPOSED: 'success',
                                        HIDDEN: 'warning',
                                        BANNED: 'error',
                                    }[content.status as string] as 'success' | 'warning' | 'error') || 'default'
                                }
                                label={content.status}
                            />
                        </Typography>
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <Typography component='h2' variant='caption'>
                            라이센스
                        </Typography>
                        <Typography variant='body2'>{content.license}</Typography>
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <Typography component='h2' variant='caption'>
                            게시일
                        </Typography>
                        <Typography variant='body2'>
                            {DateFormatter.toFormattedDate(content.createdAt as string)}
                        </Typography>
                    </div>
                </CardContent>
            </Stack>
        </Paper>
    );
};

export default ContentInfo;
