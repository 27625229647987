import { Card, CardContent, Paper } from '@mui/material';
import React from 'react';
import ModelView from '~/components/three/ModelView';

type Props = {
    url: string;
    children?: React.ReactNode;
};

const ThreeDContentView = ({ url, children }: Props) => {
    return (
        <Paper variant='outlined' square elevation={0} sx={{ width: '100%', height: '100%', minHeight: 400 }}>
            <CardContent sx={{ width: '100%', height: '100%' }}>
                <ModelView url={url} />
                {children && children}
            </CardContent>
        </Paper>
    );
};

export default ThreeDContentView;
