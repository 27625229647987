import { TipType } from '~/types/TipType';
import TipForm from './TipForm';

const TipAdd = () => {
    const tip: TipType = {
        title: '',
        isNotice: false,
        message: '',
    };

    return <TipForm tip={tip} navigateStep={-1} />;
};

export default TipAdd;
