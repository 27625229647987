import { Card, CardContent, Chip, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { UserType } from '~/types/UserType';
import {
    AdminPanelSettingsIcon,
    AccountCircleIcon,
    BookmarkAddIcon,
    ViewInArIcon,
    CastConnectedIcon,
} from '~/assets/Icons';
import { DateFormatter } from '~/utils/DateFormatter';
import { ProfileImage } from '~/components/common/ProfileImage';
import SmallIconWithText from '~/components/common/SmallIconWithText';
type Props = {
    user: UserType;
};

const UserInfo = (props: Props) => {
    let user = props.user;

    return (
        <Card variant='outlined'>
            <Stack
                direction='column'
                spacing={2}
                divider={
                    <Divider variant='inset' orientation='horizontal' flexItem>
                        {user.nickname}
                    </Divider>
                }>
                <CardContent sx={{ flex: 1 }}>
                    <Stack alignItems='center' mt={3} mb={2} spacing={1}>
                        <ProfileImage
                            src={user.url?.thumbnail}
                            sx={{ width: 100, height: 100 }}
                            title={user.nickname}
                        />
                        <Chip
                            size='small'
                            color={
                                ({
                                    USER: 'info',
                                    ADMIN: 'warning',
                                }[user.role as string] as 'warning' | 'info') || 'default'
                            }
                            icon={
                                {
                                    USER: <AccountCircleIcon />,
                                    ADMIN: <AdminPanelSettingsIcon />,
                                }[user.role as string] || <AccountCircleIcon />
                            }
                            label={user.role}
                        />
                        <Stack direction='row' spacing={3}>
                            <SmallIconWithText
                                icon={<BookmarkAddIcon sx={{ fontSize: 15 }} />}
                                title='북마크한 컨텐츠'
                                value={user.bookmarkCount}
                            />
                            <SmallIconWithText
                                icon={<CastConnectedIcon sx={{ fontSize: 15 }} />}
                                title='팔로우한 채널'
                                value={user.bookmarkCount}
                            />
                            <SmallIconWithText
                                icon={<ViewInArIcon sx={{ fontSize: 15 }} />}
                                title='게시한 컨텐츠'
                                value={user.bookmarkCount}
                            />
                        </Stack>
                    </Stack>
                </CardContent>
                <CardContent sx={{ flex: 1 }}>
                    <div>
                        <Typography component='h2' variant='caption'>
                            가입유형
                        </Typography>
                        <Typography variant='body2'>{user.socialPlatform ? user.socialPlatform : 'e-mail'}</Typography>
                    </div>
                    {user.email && (
                        <div style={{ marginTop: 15 }}>
                            <Typography component='h2' variant='caption'>
                                e-mail
                            </Typography>
                            <Typography variant='body2'>{user.email}</Typography>
                        </div>
                    )}
                    {user.introudction && (
                        <div style={{ marginTop: 15 }}>
                            <Typography component='h2' variant='caption'>
                                자기소개
                            </Typography>
                            <Typography variant='body2'>{user.introduction}</Typography>
                        </div>
                    )}
                    <div style={{ marginTop: 15 }}>
                        <Typography component='h2' variant='caption'>
                            가입일
                        </Typography>
                        <Typography variant='body2'>{DateFormatter.toFormattedDate(user.createdAt!)}</Typography>
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <Typography component='h2' variant='caption'>
                            마지막 로그인
                        </Typography>
                        <Typography variant='body2'>{DateFormatter.toFormattedDate(user.loggedAt!)}</Typography>
                    </div>
                </CardContent>
            </Stack>
        </Card>
    );
};

export default UserInfo;
