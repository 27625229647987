import { Chip, Link, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { NumberParam, ObjectParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import ListSearchBar from '~/components/common/ListSearchBar';
import { ProfileImage } from '~/components/common/ProfileImage';
import { ThumbnailImage } from '~/components/common/ThumbnailImage';
import ListPage from '~/components/list/ListPage';
import ListPagination, { PaginationListType } from '~/components/list/ListPagination';
import TableListItems, { ListColumnType } from '~/components/list/TableListItems';
import { AlertContext } from '~/contexts/AlertContext';
import { ApiContext } from '~/contexts/ApiContext';
import { DateFormatter } from '~/utils/DateFormatter';

const ScannedProducts = () => {
    const api = useContext(ApiContext);
    const [listData, setListData] = useState<PaginationListType>({});

    const [search, setSearch] = useQueryParams({
        page: withDefault(NumberParam, 1),
        size: withDefault(NumberParam, 10),
        sort: withDefault(StringParam, 'createdAt,DESC'),
        filters: withDefault(ObjectParam, null),
    });
    const [searchValue, setSearchValue] = useState<string>('');
    const handleSearch = async (e: any) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        setSearch({ filters: { search: formData.get('search_value') as string } }, 'push');
        setSearchValue(formData.get('search_value') as string);
    };
    const alert = useContext(AlertContext);

    useEffect(() => {
        api.get('/scanned-products', {
            page: search.page,
            size: search.size,
            sort: search.sort,
            filters: { search: searchValue },
        })
            .then((data) => {
                data.content = data.content?.map((obj: any) => ({
                    ...obj,
                    id: obj.productId,
                    productInfo: (
                        <div>
                            <Link underline='hover' href={`/scanned-products/${obj.productId}`}>
                                <Typography component='div' variant='caption'>
                                    {obj.productId}
                                </Typography>
                            </Link>
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <ThumbnailImage src={obj.url?.thumbnail} alt={obj.name} />
                                <Typography variant='body2'>{obj.name}</Typography>
                            </Stack>
                        </div>
                    ),
                    rendering: (
                        <div>
                            <Typography component='div' variant='caption'>
                                {obj.renderStrategy}
                            </Typography>
                            <Chip
                                size='small'
                                label={obj.status}
                                color={
                                    ({
                                        COMPLETE: 'success',
                                        PENDING: 'secondary',
                                        REQUEST: 'info',
                                        WORKING: 'primary',
                                        IN_QUEUE: 'warning',
                                        FAILURE: 'error',
                                    }[obj.status as string] as
                                        | 'success'
                                        | 'warning'
                                        | 'secondary'
                                        | 'info'
                                        | 'primary'
                                        | 'error'
                                        | 'default') || 'default'
                                }
                            />
                        </div>
                    ),
                    imageCount: (
                        <Link href={`/scanned-products/${obj.productId}/images`}>
                            {obj.uploadedImageCount} / {obj.imageTotal}
                        </Link>
                    ),
                    userInfo: (
                        <Stack direction='row' alignItems='center' spacing={0.5}>
                            <ProfileImage src={obj.user.url?.thumbnail} title={obj.user.nickname} />
                            <Typography variant='body2'>
                                <Link underline='hover' href={`/users/${obj.user.userId}`}>
                                    {obj.user.nickname}
                                </Link>
                            </Typography>
                        </Stack>
                    ),
                    changedAt: (
                        <div>
                            <Typography component='div' variant='caption'>
                                {DateFormatter.toFormattedDate(obj.createdAt as string)}
                            </Typography>
                            <Typography component='div' variant='caption'>
                                {DateFormatter.toFormattedDate(obj.updatedAt as string)}
                            </Typography>
                        </div>
                    ),
                    isEnabled: {
                        ...(obj.isEnabled === true ? (
                            <Chip size='small' label='활성화' color='success' />
                        ) : (
                            <Chip size='small' label='비활성화' color='default' />
                        )),
                    },
                }));
                setListData(data);
            })
            .catch((e: any) => {
                alert.error(e.message, -1);
            });
    }, [api, search.page, search.size, search.sort, searchValue, alert]);

    const listColumn: ListColumnType[] = [
        { column: 'id', isHidden: true, idColumn: true },
        { column: 'productInfo', title: '제품' },
        { column: 'userInfo', title: '스캔 요청자' },
        { column: 'rendering', title: '렌더링' },
        { column: 'isEnabled', title: '활성화' },
        { column: 'imageCount', title: '업로드/전체' },
        { column: 'changedAt', title: '스캔일/상태 변경일' },
    ];

    return (
        <ListPage pageTitle='스캔 제품 리스트'>
            <ListSearchBar handleSubmit={handleSearch} placeHolder='제품 이름을 입력해주세요' />
            <ListPagination listData={listData} currentPage={search.page}>
                {listData.content && <TableListItems items={listData.content} listColumn={listColumn}></TableListItems>}
            </ListPagination>
        </ListPage>
    );
};

export default ScannedProducts;
