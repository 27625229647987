import { useContext, useMemo } from 'react';
import { ApiContext } from '~/contexts/ApiContext';
import { useDispatch, useSelector } from 'react-redux';
import { setMachineIdState } from '~/stores/features/machineIdStateSlice';
import { setAuthState } from '~/stores/features/authStateSlice';

export const useAuth = () => {
    const api = useContext(ApiContext);
    const dispatch = useDispatch();
    const { machineIdState } = useSelector((state: any) => state.machineIdState || '');
    var currentDeviceId = machineIdState;
    if (!machineIdState) {
        const machineId = crypto.randomUUID();
        dispatch(setMachineIdState(machineId));
        currentDeviceId = machineId;
    }

    return useMemo(() => {
        return {
            signIn: async (email: string, password: string) => {
                const deviceId = currentDeviceId;
                const deviceType = 'PC';
                const autologin = false;
                try {
                    const data = await api.post('/sign-in', {
                        email,
                        password,
                        deviceId,
                        deviceType,
                        autologin,
                    });
                    console.log(data);
                    if (data.role !== 'ADMIN') {
                        throw new Error('관리자 권한이 없습니다.');
                    }
                    dispatch(setAuthState({ authentication: true, manager: data }));
                } catch (e) {
                    throw e;
                }
            },
            signOut: async () => {
                dispatch(setAuthState({ authentication: false, manager: null }));
            },
        };
    }, [api, dispatch, currentDeviceId]);
};
