import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '~/contexts/ApiContext';
import ListPage from '~/components/list/ListPage';
import TableListItems, { ListColumnType } from '~/components/list/TableListItems';
import ListPagination, { PaginationListType } from '~/components/list/ListPagination';
import { useQueryParams, NumberParam, withDefault, ObjectParam } from 'use-query-params';
import { Button, Chip, Link, Stack, Typography } from '@mui/material';
import { ProfileImage } from '~/components/common/ProfileImage';
import HiddenValue from '~/components/common/HiddenValue';
import { AlertContext } from '~/contexts/AlertContext';
import { DateFormatter } from '~/utils/DateFormatter';
import { ActionDialogContext } from '~/contexts/ActionDialogContext';
import ListSearchBar from '~/components/common/ListSearchBar';

const UserDevices = () => {
    const actionDialog = useContext(ActionDialogContext);
    const api = useContext(ApiContext);
    const alert = useContext(AlertContext);
    const [search, setSearch] = useQueryParams({
        page: withDefault(NumberParam, 1),
        size: withDefault(NumberParam, 10),
        filters: withDefault(ObjectParam, null),
    });
    const [searchValue, setSearchValue] = useState<string>('');
    const handleSearch = async (e: any) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        setSearch({ filters: { search: formData.get('search_value') as string } }, 'push');
        setSearchValue(formData.get('search_value') as string);
    };

    const [listData, setListData] = useState<PaginationListType>({});

    const showOptions = (options: string) => {
        actionDialog.action(
            'Options',
            <div style={{ maxWidth: '100%', wordBreak: 'break-all' }}>{JSON.stringify(options, null, 2)}</div>
        );
    };

    useEffect(() => {
        api.get('/users/user-devices', { page: search.page, size: search.size, filters: { search: searchValue } })
            .then((data) => {
                data.content = data.content?.map((obj: any) => ({
                    ...obj,
                    id: obj.userDeviceId,
                    userInfo: (
                        <Stack direction='row' spacing={0.5} alignItems='center'>
                            <ProfileImage src={obj.user.url?.thumbnail} title={obj.user.nickname} />
                            <Link underline='hover' href={'/users/' + obj.userId}>
                                <Typography variant='body2'>{obj.user.nickname}</Typography>
                            </Link>
                        </Stack>
                    ),
                    deviceType: (
                        <Chip
                            size='small'
                            label={obj.deviceType}
                            color={
                                ({
                                    IOS: 'warning',
                                    PC: 'primary',
                                    ANDROID: 'success',
                                }[obj.deviceType as string] as 'warning' | 'success' | 'primary') || 'default'
                            }
                        />
                    ),
                    userDeviceId: <HiddenValue value={obj.userDeviceId} />,
                    deviceId: <HiddenValue value={obj.deviceId} />,
                    token: <HiddenValue value={obj.token} />,
                    options: (
                        <Button
                            variant='outlined'
                            size='small'
                            sx={{ padding: 0, minWidth: 50 }}
                            disabled={obj.options === null ? true : false}
                            onClick={() => showOptions(obj.options)}
                            color='primary'>
                            보기
                        </Button>
                    ),
                    registeredAt: (
                        <div>
                            <Typography component='div' variant='caption'>
                                {DateFormatter.toFormattedDate(obj.createdAt as string)}
                            </Typography>
                        </div>
                    ),
                }));
                setListData(data);
            })
            .catch((e: any) => {
                alert.error(e.message);
            });
    }, [api, alert, search.page, search.size, searchValue]);

    const listColumn: ListColumnType[] = [
        { column: 'id', isHidden: true, idColumn: true },
        { column: 'userInfo', title: '사용자' },
        { column: 'deviceType', title: '디바이스유형' },
        { column: 'userDeviceId', title: 'UserDeviceId' },
        { column: 'deviceId', title: 'DeviceId' },
        { column: 'token', title: 'Token' },
        { column: 'options', title: 'Options' },
        { column: 'registeredAt', title: '등록일' },
    ];

    return (
        <ListPage pageTitle='사용자 디바이스 리스트'>
            <ListSearchBar handleSubmit={handleSearch} placeHolder='사용자 닉네임을 입력해주세요' />
            <ListPagination listData={listData} currentPage={search.page}>
                {listData.content && <TableListItems items={listData.content} listColumn={listColumn} />}
            </ListPagination>
        </ListPage>
    );
};

export default UserDevices;
