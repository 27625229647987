import React from 'react';
import { Card, Divider, CardContent, Chip, Stack, Typography } from '@mui/material';
import { ChannelType } from '~/types/ChannelType';
import { ProfileImage } from '~/components/common/ProfileImage';
import { ShoppingCartIcon, GroupAddIcon, ViewInArIcon } from '~/assets/Icons';
import { DateFormatter } from '~/utils/DateFormatter';
import SmallIconWithText from '~/components/common/SmallIconWithText';
import ChannelOwner from './ChannelOwner';

type Props = {
    channel: ChannelType;
};

const ChannelInfo = ({ channel }: Props) => {
    return (
        <Card variant='outlined'>
            <Stack
                direction='column'
                spacing={2}
                divider={
                    <Divider variant='inset' orientation='horizontal' flexItem>
                        {channel.name}
                    </Divider>
                }>
                <CardContent sx={{ flex: 1 }}>
                    <Stack alignItems='center' mt={3} mb={2} spacing={1}>
                        <ProfileImage
                            src={channel.url?.thumbnail}
                            sx={{ width: 100, height: 100 }}
                            title={channel.name}
                        />
                        <Chip
                            size='small'
                            color={
                                ({
                                    ENABLED: 'success',
                                    DISABLED: 'default',
                                    BANNED: 'warning',
                                }[channel.status as string] as 'success' | 'warning' | 'default') || 'default'
                            }
                            label={channel.status}
                        />
                        {channel.description && <Typography variant='caption'>{channel.description}</Typography>}
                        <Stack direction='row' spacing={3}>
                            <SmallIconWithText
                                icon={<GroupAddIcon sx={{ fontSize: 15 }} />}
                                title='채널 팔로워'
                                value={channel.contentCount}
                            />
                            <SmallIconWithText
                                icon={<ViewInArIcon sx={{ fontSize: 15 }} />}
                                title='게시된 3D 컨텐츠'
                                value={channel.contentCount}
                            />
                            <SmallIconWithText
                                icon={<ShoppingCartIcon sx={{ fontSize: 15 }} />}
                                title='거래 이력'
                                value={channel.dealCount}
                            />
                        </Stack>
                    </Stack>
                </CardContent>
                <CardContent sx={{ flex: 1 }}>
                    <ChannelOwner channel={channel} />
                    <div style={{ marginTop: 15 }}>
                        <Typography component='h2' variant='caption'>
                            생성일
                        </Typography>
                        <Typography variant='body2'>{DateFormatter.toFormattedDate(channel.createdAt!)}</Typography>
                    </div>
                </CardContent>
            </Stack>
        </Card>
    );
};

export default ChannelInfo;
