export const DateFormatter = {
    toDate(str: string) {
        const [dateComponents, timeComponents] = str.split('T');
        const [year, month, day] = dateComponents.split('-');
        const [hours, minutes, seconds] = timeComponents.split(':');

        return new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
    },
    toFormattedDate(date: string | Date) {
        if (typeof date === 'string') {
            return this.toDate(date).toLocaleString('ko-KR');
        } else {
            return date.toLocaleString('ko-KR');
        }
    },
};
