import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '~/contexts/ApiContext';
import { useNavigate, useLocation } from 'react-router-dom';
import ViewPage from '~/components/view/ViewPage';
import {
    Button,
    Card,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Stack,
    Switch,
    Typography,
} from '@mui/material';
import { TipType } from '~/types/TipType';

import { AlertContext } from '~/contexts/AlertContext';
import { ActionDialogContext } from '~/contexts/ActionDialogContext';
import { ProfileImage } from '~/components/common/ProfileImage';

import ValidTextField from '~/components/form/ValidTextField';
import { useForm } from 'react-hook-form';
import ValidMDEditor from '~/components/form/ValidMDEditor';

type TipFormProps = {
    tip: TipType;
    navigateStep?: number;
};
const TipForm = ({ tip, navigateStep }: TipFormProps) => {
    const api = useContext(ApiContext);
    const alert = useContext(AlertContext);
    const actionDialog = useContext(ActionDialogContext);

    const [isNotice, setIsNotice] = useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setValue('title', tip.title ? tip.title : '');
        setValue('isNotice', tip.isNotice ? tip.isNotice : false);
        setIsNotice(tip.isNotice ? tip.isNotice : false);
        setValue('message', tip.message ? tip.message : '');
    }, [tip]);

    const handleDelete = () => {
        api.delete(location.pathname)
            .then(() => {
                alert.success('삭제 되었습니다', -1);
                actionDialog.closeDialog();
            })
            .catch((e: any) => {
                alert.error(e.message);
            });
    };

    const clickDelete = () => {
        const actionButton = (
            <Button variant='contained' onClick={handleDelete} color='error'>
                삭제
            </Button>
        );
        actionDialog.action('Tip 삭제', '정말 삭제하시겠습니까?', actionButton);
    };

    type FormValues = {
        title: string;
        isNotice: boolean;
        message: string;
    };

    const { getValues, setValue, handleSubmit, control } = useForm<FormValues>({
        defaultValues: {
            title: tip.title,
            isNotice: tip.isNotice,
            message: tip.message,
        },
        mode: 'onBlur',
    });

    const onSubmit = async (data: FormValues) => {
        const formData = new FormData();
        formData.append('title', data.title);
        formData.append('isNotice', data.isNotice ? 'true' : 'false');
        formData.append('message', data.message);

        try {
            await api.post(location.pathname, formData);
        } catch (e: any) {
            alert.error(e.message);
            return;
        }
        alert.success('저장 되었습니다', navigateStep);
    };

    return (
        <ViewPage pageTitle='Tip'>
            <Card
                variant='outlined'
                component='form'
                autoComplete='off'
                sx={{ p: 2 }}
                onSubmit={handleSubmit(onSubmit)}>
                {tip.user && (
                    <FormGroup sx={{ marginBottom: 2 }}>
                        <FormLabel>작성자</FormLabel>
                        <Stack direction='row' alignItems='center' spacing={1}>
                            <ProfileImage
                                src={tip.user.url?.thumbnail}
                                title={tip.user.nickname}
                                sx={{ width: 50, height: 50 }}
                            />
                            <Typography variant='body2'>{tip.user.nickname}</Typography>
                        </Stack>
                    </FormGroup>
                )}
                <FormGroup>
                    <FormLabel>제목</FormLabel>
                    <FormControl size='small'>
                        <ValidTextField
                            control={control}
                            name='title'
                            rules={{ required: '제목을 입력해 주세요' }}
                            size='small'
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup sx={{ marginTop: 2 }}>
                    <FormLabel>공지 여부</FormLabel>
                    <FormControlLabel
                        control={
                            <Switch
                                value={true}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setValue('isNotice', e.target.checked);
                                    setIsNotice(e.target.checked);
                                }}
                                checked={isNotice}
                                name='isNotice'
                            />
                        }
                        label={getValues('isNotice') === true ? '공지글 입니다.' : '공지글이 아닙니다.'}
                    />
                </FormGroup>
                <FormGroup sx={{ marginTop: 2 }}>
                    <FormLabel>내용</FormLabel>
                    <ValidMDEditor
                        height={500}
                        rules={{ required: '내용을 입력해 주세요' }}
                        control={control}
                        name='message'
                    />
                </FormGroup>
                <FormGroup sx={{ marginTop: 2, alignContent: 'flex-end' }}>
                    <Stack spacing={2} direction='row'>
                        {tip.user && (
                            <Button variant='contained' onClick={clickDelete} color='error'>
                                삭제
                            </Button>
                        )}
                        <Button variant='contained' type='submit'>
                            저장하기
                        </Button>
                        <Button variant='outlined' onClick={() => navigate(-1)}>
                            뒤로
                        </Button>
                    </Stack>
                </FormGroup>
            </Card>
        </ViewPage>
    );
};

export default TipForm;
