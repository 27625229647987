import { Avatar, Stack, styled, Tooltip, Typography, useTheme } from '@mui/material';
import React from 'react';
import { CommonUtil } from '~/utils/CommonUtil';

const SmallIconAvatar = styled(Avatar)(({ theme }) => ({
    width: 20,
    height: 20,
}));

type Props = {
    icon?: React.ReactElement;
    title?: string;
    value?: string | number;
};
const SmallIconWithText = ({ icon, title, value }: Props) => {
    const theme = useTheme();
    return (
        <Stack direction='row'>
            <Tooltip title={title ? title : ''} arrow>
                <SmallIconAvatar
                    sx={{ bgcolor: title ? CommonUtil.stringToColor(title) : theme.palette.info.light }}
                    alt={title ? title : ''}>
                    {icon}
                </SmallIconAvatar>
            </Tooltip>
            <Typography variant='body2' ml={1}>
                {value}
            </Typography>
        </Stack>
    );
};

export default SmallIconWithText;
