import React, { useContext, useEffect, useState } from 'react';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { PageTitleProps } from '~/components/common/PageTitle';
import ViewPage from '~/components/view/ViewPage';
import { ApiContext } from '~/contexts/ApiContext';
import { ChannelType } from '~/types/ChannelType';
import { UserType } from '~/types/UserType';
import UserChannelFollowers from './UserChannelFollowers';
import UserChannelContents from './UserChannelContents';
import UserChannelMembers from './UserChannelMembers';

type Props = {
    user?: UserType;
    subPage?: boolean;
};

const UserChannel = (props: Props) => {
    const api = useContext(ApiContext);
    const [channel, setChannel] = useState<ChannelType>({});
    const userId = props.user?.userId;

    useEffect(() => {
        api.get(`/users/${userId}/owned-channel`).then((data) => {
            setChannel(data);
        });
    }, [userId, api]);

    const pageTitleProps: PageTitleProps = {
        component: 'h2',
        variant: 'subtitle1',
        color: 'default',
    };

    return (
        <ViewPage>
            <Stack
                direction='column'
                spacing={2}
                divider={<Divider variant='inset' orientation='horizontal' flexItem />}>
                <ViewPage pageTitle='사용자 채널' pageTitleProps={pageTitleProps}>
                    <Grid container sx={{ marginTop: 2 }}>
                        <Grid item md={4} xs={12}>
                            <Typography variant='caption'>채널 명</Typography>
                            <Typography>{channel.name}</Typography>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='caption'>팔로워</Typography>
                            <Typography>{channel.followerCount}</Typography>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='caption'>컨텐츠</Typography>
                            <Typography>{channel.contentCount}</Typography>
                        </Grid>
                    </Grid>
                    {channel.channelId && <UserChannelMembers channel={channel} />}
                </ViewPage>
                {channel.channelId && channel.followerCount! > 0 && <UserChannelFollowers channel={channel} />}
                {channel.channelId && channel.contentCount! > 0 && <UserChannelContents channel={channel} />}
            </Stack>
        </ViewPage>
    );
};

export default UserChannel;