import React from 'react';
import { TextField, TextFieldProps, Typography } from '@mui/material';
import { TControl } from '~/types/TControl';
import { FieldValues, useController } from 'react-hook-form';

type TProps<T extends FieldValues> = TextFieldProps & TControl<T>;

function ValidTextField<T extends FieldValues>({ name, rules, control, ...props }: TProps<T>) {
    const {
        field: { value, onChange },
        fieldState: { isDirty, isTouched, error },
    } = useController({ name, rules, control });

    return (
        <>
            <TextField value={value} onChange={onChange} error={error ? true : false} {...props} />
            <Typography variant='caption' color='error'>
                {error ? error.message : ''}
            </Typography>
        </>
    );
}

export default ValidTextField;
