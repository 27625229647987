import { Link, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '~/contexts/ApiContext';
import { ChannelType } from '~/types/ChannelType';
import { UserType } from '~/types/UserType';

type Props = {
    channel: ChannelType;
};
const ChannelOwner = ({ channel }: Props) => {
    const api = useContext(ApiContext);
    const [owner, setOwner] = useState<UserType>({});
    useEffect(() => {
        api.get(`/channels/${channel.channelId}/owner`).then((data) => {
            setOwner(data);
        });
    }, [api, channel]);

    return (
        <div>
            <Typography component='h2' variant='caption'>
                소유자
            </Typography>
            <Typography variant='body2'>
                <Link href={`/users/${owner.userId}`}>{owner.nickname}</Link>
            </Typography>
        </div>
    );
};

export default ChannelOwner;
