import { Button, Menu, MenuItem, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { TaskAltIcon, UnpublishedIcon } from '~/assets/Icons';
import { ActionDialogContext } from '~/contexts/ActionDialogContext';
import { AlertContext } from '~/contexts/AlertContext';
import { ApiContext } from '~/contexts/ApiContext';

type Props = {
    contentCommentId: number | null;
    userId: number | null;
    anchorEl: Element | null;
    open: boolean;
    onClose?: () => void;
};

const CommentReportsMenu = (props: Props) => {
    const [open, setOpen] = useState(false);
    const [contentCommentId, setContentCommentId] = useState<number | null>(null);
    const [userId, setUserId] = useState<number | null>(null);
    var comment: { complete: any; reject: any } = { complete: '', reject: '' };

    const handleMenuClose = () => {
        setOpen(false);
        comment = { complete: '', reject: '' };
        if (props.onClose !== undefined) {
            props.onClose();
        }
    };
    const api = useContext(ApiContext);
    const actionDialog = useContext(ActionDialogContext);
    const alert = useContext(AlertContext);

    useEffect(() => {
        setOpen(props.open);
        setContentCommentId(props.contentCommentId);
        setUserId(props.userId);
    }, [props]);

    return (
        <Menu
            id='item-action-menu'
            anchorEl={props.anchorEl}
            open={open}
            onClose={handleMenuClose}
            PaperProps={{
                style: {
                    width: 165,
                },
            }}>
            <MenuItem
                key={'complete_item'}
                onClick={() => {
                    actionDialog.action(
                        '신고 Complete',
                        <TextField
                            label='Comment'
                            multiline
                            rows={4}
                            sx={{ width: '100%', mt: 2 }}
                            onChange={(e) => {
                                console.log('aaa');
                                comment.complete = e.target.value;
                            }}
                        />,
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={async () => {
                                const formData = new FormData();
                                formData.append('contentCommentId', String(contentCommentId));
                                formData.append('userId', String(userId));
                                formData.append('comment', comment.complete);

                                await api
                                    .post('/reports/comments/complete', formData)
                                    .then(() => {
                                        alert.success('처리 되었습니다');
                                    })
                                    .catch((e: any) => {
                                        alert.error(e.message);
                                    });

                                actionDialog.closeDialog();
                                handleMenuClose();
                            }}>
                            COMPLETE
                        </Button>
                    );
                }}>
                <TaskAltIcon color='info' fontSize='small' sx={{ marginRight: 2 }} /> COMPLETE
            </MenuItem>
            <MenuItem
                key={'reject_item'}
                onClick={() => {
                    actionDialog.action(
                        '신고 Reject',
                        <TextField
                            label='Comment'
                            multiline
                            rows={4}
                            sx={{ width: '100%', mt: 2 }}
                            onChange={(e) => {
                                comment.reject = e.target.value;
                            }}
                        />,
                        <Button
                            variant='contained'
                            color='error'
                            onClick={async () => {
                                const formData = new FormData();
                                formData.append('contentCommentId', String(contentCommentId));
                                formData.append('userId', String(userId));
                                formData.append('comment', comment.reject);

                                await api
                                    .post('/reports/comments/reject', formData)
                                    .then(() => {
                                        alert.success('처리 되었습니다');
                                    })
                                    .catch((e: any) => {
                                        alert.error(e.message);
                                    });

                                actionDialog.closeDialog();
                                handleMenuClose();
                            }}>
                            REJECT
                        </Button>
                    );
                }}>
                <UnpublishedIcon color='error' fontSize='small' sx={{ marginRight: 2 }} /> REJECT
            </MenuItem>
        </Menu>
    );
};

export default CommentReportsMenu;
