import ScanNoImage from '~/assets/images/scan-noimage.png';

export const ThumbnailImage = ({ src, alt, style, ...props }: any) => {
    return (
        <img
            src={src}
            style={{ height: 40, width: 40, objectFit: 'cover', ...style }}
            alt={alt}
            onError={(e: any) => {
                e.target.error = null;
                e.target.src = ScanNoImage;
            }}
        />
    );
};
