import React, { useState, useEffect, useContext } from 'react';
import { ChannelType } from '~/types/ChannelType';
import { ApiContext } from '~/contexts/ApiContext';
import ViewPage from '~/components/view/ViewPage';
import { PageTitleProps } from '~/components/common/PageTitle';
import { IconButton, ImageList, ImageListItem, ImageListItemBar, Link } from '@mui/material';

import { ContentType } from '~/types/ContentType';
import { MoreHorizIcon } from '~/assets/Icons';

type Props = {
    channel?: ChannelType;
};
const UserChannelContents = (props: Props) => {
    const api = useContext(ApiContext);
    const channel: ChannelType = props.channel!;
    const [contentsList, setContentsList] = useState<ContentType[]>([]);
    const search = { page: 1, size: 3 };

    useEffect(() => {
        api.get(`/channels/${channel.channelId}/contents`, search).then((data) => {
            setContentsList(data.content);
        });
    }, [channel, api]);

    const pageTitleProps: PageTitleProps = {
        component: 'h2',
        variant: 'subtitle1',
        color: 'default',
    };

    return (
        <ViewPage
            pageTitle='최신 컨텐츠'
            pageTitleProps={pageTitleProps}
            pageActionButton={
                (channel.contentCount as number) > contentsList.length ? (
                    <Link href={`/channels/${channel.channelId}#contents`}>
                        <IconButton aria-label='더보기' size='small'>
                            <MoreHorizIcon fontSize='small' />
                        </IconButton>
                    </Link>
                ) : (
                    ''
                )
            }>
            <ImageList cols={3}>
                {contentsList.map((content) => (
                    <ImageListItem key={content.contentId}>
                        <img src={content.url?.thumbnail} alt={content.title} loading='lazy' />
                        <Link href={`/posted-contents/${content.contentId}`}>
                            <ImageListItemBar
                                title={content.title}
                                subtitle={content.categories?.map((category: any) => `#${category.name}`)}
                            />
                        </Link>
                    </ImageListItem>
                ))}
            </ImageList>
        </ViewPage>
    );
};

export default UserChannelContents;
