import { useContext, useEffect, useState } from 'react';
import { ApiContext } from '~/contexts/ApiContext';
import ListPage from '~/components/list/ListPage';
import TableListItems, { ListColumnType } from '~/components/list/TableListItems';
import ListPagination, { PaginationListType } from '~/components/list/ListPagination';
import { useQueryParams, NumberParam, withDefault, StringParam, ObjectParam } from 'use-query-params';
import { Button, Link, Stack, Typography } from '@mui/material';
import { AlertContext } from '~/contexts/AlertContext';
import { DateFormatter } from '~/utils/DateFormatter';
import { ProfileImage } from '~/components/common/ProfileImage';
import { ThumbnailImage } from '~/components/common/ThumbnailImage';
import ListSearchBar from '~/components/common/ListSearchBar';
import { useNavigate } from 'react-router-dom';

const PostedContents = () => {
    const api = useContext(ApiContext);
    const [listData, setListData] = useState<PaginationListType>({});

    const [search, setSearch] = useQueryParams({
        page: withDefault(NumberParam, 1),
        size: withDefault(NumberParam, 10),
        sort: withDefault(StringParam, 'createdAt,DESC'),
        filters: withDefault(ObjectParam, null),
    });
    const [searchValue, setSearchValue] = useState<string>('');
    const handleSearch = async (e: any) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        setSearch({ filters: { search: formData.get('search_value') as string } }, 'push');
        setSearchValue(formData.get('search_value') as string);
    };
    const alert = useContext(AlertContext);
    const navigate = useNavigate();

    useEffect(() => {
        api.get('/posted-contents', {
            page: search.page,
            size: search.size,
            sort: search.sort,
            filters: { search: searchValue },
        })
            .then((data) => {
                console.log(data);
                data.content = data.content?.map((obj: any) => ({
                    ...obj,
                    id: obj.contentId,
                    contentLink: (
                        <Link underline='hover' href={`/posted-contents/${obj.contentId}`}>
                            {obj.contentId}
                        </Link>
                    ),
                    contentInfo: (
                        <Stack direction='row' alignItems='center' spacing={1}>
                            <ThumbnailImage src={obj.url?.thumbnail} alt={obj.title} />
                            <Typography variant='body2'>{obj.title}</Typography>
                        </Stack>
                    ),
                    channelInfo: (
                        <Stack direction='row' alignItems='center' spacing={0.5}>
                            <ProfileImage src={obj.channel.url?.thumbnail} title={obj.channel.name} />
                            <Typography variant='body2'>
                                <Link underline='hover' href={`/channels/${obj.channel.channelId}`}>
                                    {obj.channel.name}
                                </Link>
                            </Typography>
                        </Stack>
                    ),
                    changedAt: (
                        <div>
                            <Typography component='div' variant='caption'>
                                {DateFormatter.toFormattedDate(obj.createdAt as string)}
                            </Typography>
                            <Typography component='div' variant='caption'>
                                {DateFormatter.toFormattedDate(obj.updatedAt as string)}
                            </Typography>
                        </div>
                    ),
                }));
                setListData(data);
            })
            .catch((e: any) => {
                alert.error(e.message);
            });
    }, [api, search.page, search.size, search.sort, searchValue, alert]);

    const listColumn: ListColumnType[] = [
        { column: 'id', isHidden: true, idColumn: true },
        { column: 'contentLink', title: 'ID' },
        { column: 'contentInfo', title: '컨텐츠' },
        { column: 'channelInfo', title: '채널' },
        { column: 'hit', title: '조회수' },
        { column: 'commentCount', title: '댓글수' },
        { column: 'changedAt', title: '게시일 / 수정일' },
    ];

    return (
        <ListPage pageTitle='게시된 3D 리스트'>
            <ListSearchBar handleSubmit={handleSearch} placeHolder='컨텐츠 이름을 입력해주세요' />
            <ListPagination
                listData={listData}
                currentPage={search.page}
                buttonNodes={
                    <Button variant='outlined' onClick={() => navigate('/contents/post')}>
                        3D 컨텐츠 게시
                    </Button>
                }>
                {listData.content && <TableListItems items={listData.content} listColumn={listColumn}></TableListItems>}
            </ListPagination>
            {/*
            <ListPagination
                listData={listData}
                currentPage={search.page}
                buttonNodes={
                    <Button variant='outlined' onClick={() => navigate('/contents/post')}>
                        등록
                    </Button>
                }>
                {listData.content && <TableListItems items={listData.content} listColumn={listColumn}></TableListItems>}
            </ListPagination>
            */}
        </ListPage>
    );
};

export default PostedContents;
