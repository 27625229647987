import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type authState = {
    authentication: Boolean;
    manager: any;
};

const initialState: authState = {
    authentication: false,
    manager: {},
};

export const authStateSlice = createSlice({
    name: 'authState',
    initialState,
    reducers: {
        setAuthState: (
            state,
            action: PayloadAction<{ authentication: boolean; manager: any }>
        ) => {
            state.authentication = action.payload.authentication;
            state.manager = action.payload.manager;
        },
    },
});

export const { setAuthState } = authStateSlice.actions;

export default authStateSlice.reducer;
