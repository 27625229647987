import {
    Box,
    Button,
    Card,
    Checkbox,
    Chip,
    FormControl,
    FormGroup,
    FormLabel,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ProfileImage } from '~/components/common/ProfileImage';
import { ThumbnailImage } from '~/components/common/ThumbnailImage';
import ValidFileInput from '~/components/form/ValidFileInput';
import ValidSelect, { ValidSelectItem } from '~/components/form/ValidSelect';
import ValidTextField from '~/components/form/ValidTextField';
import ViewPage from '~/components/view/ViewPage';
import { AlertContext } from '~/contexts/AlertContext';
import { ApiContext } from '~/contexts/ApiContext';
import { ChannelType } from '~/types/ChannelType';

const ContentPostForm = () => {
    const [channels, setChannels] = useState<ChannelType[]>([]);
    const [categories, setCategories] = useState<any[]>([]);
    const licenses = ['CC_BY', 'CC_BY_NC', 'CC_BY_ND', 'CC_BY_SA', 'CC_BY_NC_SA', 'CC_BY_NC_ND'];
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

    const api = useContext(ApiContext);
    const alert = useContext(AlertContext);
    const navigate = useNavigate();

    type FormValues = {
        channel: string;
        title: string;
        description: string;
        categoryId: string[];
        license: string;
        srcFile: File;
        iosSrcFile: File;
        thumbnailFile: File;
    };

    const { getValues, setValue, handleSubmit, control } = useForm<FormValues>({
        defaultValues: {
            channel: '',
            title: '',
            description: '',
            categoryId: [],
            license: '',
        },
        mode: 'onBlur',
    });

    const onSubmit = async (data: FormValues) => {
        const formData = new FormData();
        formData.append('channel', data.channel);
        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('categoryId', data.categoryId.join(','));
        formData.append('license', data.license);
        formData.append('srcFile', data.srcFile);
        formData.append('iosSrcFile', data.iosSrcFile);
        formData.append('thumbnailFile', data.thumbnailFile);

        try {
            await api.post(`/channels/${formData.get('channel')}/contents`, formData);
        } catch (e: any) {
            alert.error(e.message);
            return;
        }
        alert.success('게시 되었습니다.', -1);
    };

    const handleCategoryChange = (e: any) => {
        if (e.target.value.length > 3) {
            alert.error('카테고리는 최대 3개까지 선택 가능합니다.');
            throw new Error('카테고리는 최대 3개까지 선택 가능합니다.');
        }
        setSelectedCategories(e.target.value);
    };

    useEffect(() => {
        api.get('/channels/postable-channels')
            .then((data) => {
                setChannels(data);
            })
            .catch((e) => {
                alert.error(e);
            });

        api.get('/categories')
            .then((data) => {
                setCategories(data);
            })
            .catch((e) => {
                alert.error(e);
            });
    }, []);

    const channelItems: ValidSelectItem[] = channels.map((channel: ChannelType) => ({
        label: (
            <Stack direction='row' alignItems='center' spacing={1}>
                <ProfileImage sx={{ width: '30px', height: '30px' }} src={channel.url?.thumbnail} alt={channel.title} />
                <Typography variant='body2'>@{channel.name}</Typography>
            </Stack>
        ),
        value: channel.channelId!,
    }));

    const licenseItems: ValidSelectItem[] = licenses.map((license: string) => ({
        label: (
            <Stack direction='row' alignItems='center' spacing={1}>
                <ThumbnailImage
                    style={{ width: 57, height: 20 }}
                    src={require(`../../assets/images/${license.toLowerCase()}.png`)}
                />
                <Typography variant='body2'>{license}</Typography>
            </Stack>
        ),
        value: license,
    }));

    const categoryItems: ValidSelectItem[] = categories.map((category: any) => ({
        label: (
            <>
                <Checkbox checked={selectedCategories.indexOf(category.categoryId) > -1} />
                <ListItemText primary={category.name} />
            </>
        ),
        value: category.categoryId,
    }));
    return (
        <ViewPage pageTitle='3D 컨텐츠 게시'>
            <Card
                variant='outlined'
                component='form'
                noValidate
                autoComplete='off'
                sx={{ p: 2 }}
                onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                    <FormLabel>게시 채널</FormLabel>
                    <FormControl size='small' sx={{ marginTop: 1 }} required>
                        <ValidSelect
                            selectList={channelItems}
                            placeholder='채널을 선택하세요.'
                            rules={{ required: '채널을 선택해 주세요.' }}
                            control={control}
                            name='channel'
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup sx={{ marginTop: 2 }}>
                    <FormLabel>3D GLB File(*.glb)</FormLabel>
                    <FormControl size='small' sx={{ marginTop: 1 }}>
                        <ValidFileInput
                            size='small'
                            name='srcFile'
                            rules={{ required: 'GLB 파일을 선택해 주세요.' }}
                            control={control}
                            inputProps={{ accept: '.glb' }}
                            placeholder='glb 파일을 선택하세요.'
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup sx={{ marginTop: 2 }}>
                    <FormLabel>3D USDZ File(*.usdz) - (ios AR)</FormLabel>
                    <FormControl size='small' sx={{ marginTop: 1 }}>
                        <ValidFileInput
                            size='small'
                            name='iosSrcFile'
                            rules={{ required: false }}
                            control={control}
                            inputProps={{ accept: '.usdz' }}
                            placeholder='usdz 파일을 선택하세요.'
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup sx={{ marginTop: 2 }}>
                    <FormLabel>Thumbnail File</FormLabel>
                    <FormControl size='small' sx={{ marginTop: 1 }}>
                        <ValidFileInput
                            size='small'
                            name='thumbnailFile'
                            rules={{ required: '썸네일 이미지를 선택해 주세요.' }}
                            control={control}
                            inputProps={{ accept: 'image/*' }}
                            placeholder='썸네일 이미지를 선택하세요.'
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup sx={{ marginTop: 2 }}>
                    <FormLabel>제목</FormLabel>
                    <FormControl size='small' sx={{ marginTop: 1 }}>
                        <ValidTextField
                            control={control}
                            name='title'
                            rules={{ required: '제목을 입력해 주세요.' }}
                            size='small'
                            placeholder='컨텐츠 제목을 입력하세요.'
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup sx={{ marginTop: 2 }}>
                    <FormLabel>설명</FormLabel>
                    <FormControl size='small' sx={{ marginTop: 1 }}>
                        <ValidTextField
                            control={control}
                            name='description'
                            rules={{ required: false }}
                            size='small'
                            multiline
                            rows={3}
                            placeholder='컨텐츠 설명을 입력하세요.'
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup sx={{ marginTop: 2 }}>
                    <FormLabel>카테고리</FormLabel>
                    <FormControl size='small' sx={{ marginTop: 1 }}>
                        <ValidSelect
                            name='categoryId'
                            control={control}
                            selectList={categoryItems}
                            onChange={handleCategoryChange}
                            multiple
                            rules={{ required: '카테고리를 선택해 주세요.' }}
                            renderValue={(selected: any) => (
                                <Box component='div' sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value: any, name: string) => (
                                        <Chip
                                            color='primary'
                                            key={value}
                                            label={categories.find((item) => item.categoryId === value).name}
                                        />
                                    ))}
                                </Box>
                            )}
                            placeholder={'카테고리를 선택하세요.'}
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup sx={{ marginTop: 2 }}>
                    <FormLabel>라이센스</FormLabel>
                    <FormControl size='small' sx={{ marginTop: 1 }} required>
                        <ValidSelect
                            selectList={licenseItems}
                            placeholder='라이센스를 선택하세요.'
                            rules={{ required: '라이센스를 선택해 주세요.' }}
                            control={control}
                            name='license'
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup sx={{ marginTop: 2, alignContent: 'flex-end' }}>
                    <Stack spacing={2} direction='row'>
                        <Button variant='contained' type='submit'>
                            저장하기
                        </Button>
                        <Button variant='outlined' onClick={() => navigate(-1)}>
                            뒤로
                        </Button>
                    </Stack>
                </FormGroup>
            </Card>
        </ViewPage>
    );
};

export default ContentPostForm;
