import { useContext, useEffect, useState } from 'react';
import { ApiContext } from '~/contexts/ApiContext';
import ListPage from '~/components/list/ListPage';
import TableListItems, { ListColumnType } from '~/components/list/TableListItems';
import ListPagination, { PaginationListType } from '~/components/list/ListPagination';
import { useQueryParams, NumberParam, withDefault } from 'use-query-params';
import { Chip, IconButton, Link, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { AlertContext } from '~/contexts/AlertContext';
import { ErrorIcon, HelpIcon, MoreVertIcon } from '~/assets/Icons';
import { CommonUtil } from '~/utils/CommonUtil';
import { DateFormatter } from '~/utils/DateFormatter';
import { ThumbnailImage } from '~/components/common/ThumbnailImage';
import CommentReportsMenu from './CommentReportsMenu';
import { ProfileImage } from '~/components/common/ProfileImage';

const CommentReports = () => {
    const api = useContext(ApiContext);
    const [listData, setListData] = useState<PaginationListType>({});
    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [contentCommentId, setContentCommentId] = useState<number | null>(null);
    const [userId, setUserId] = useState<number | null>(null);
    const [search] = useQueryParams({
        page: withDefault(NumberParam, 1),
        size: withDefault(NumberParam, 10),
    });
    const alert = useContext(AlertContext);
    const theme = useTheme();

    const handleMenuOpen = (contentCommentId: number, userId: number, e: any) => {
        setContentCommentId(contentCommentId);
        setUserId(userId);
        setMenuOpen(true);
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setMenuOpen(false);
    };

    useEffect(() => {
        api.get('/reports/comments', search)
            .then((data) => {
                data.content = data.content?.map((obj: any) => ({
                    ...obj,
                    id: obj.contentComment.contentCommentId.toString() + '_' + obj.user.userId.toString(),
                    contentInfo: (
                        <Stack direction='row' alignItems='center' spacing={1}>
                            <ThumbnailImage
                                src={obj.contentComment.content.url?.thumbnail}
                                alt={obj.contentComment.content.title}
                            />
                            <Stack direction='column' justifyContent='center'>
                                <Typography variant='body2'>{obj.contentComment.content.title}</Typography>
                                <Typography variant='caption'>
                                    <Link underline='hover' href={'/users/' + obj.contentComment.content.userId}>
                                        @{obj.contentComment.content.channel.name}
                                    </Link>
                                </Typography>
                            </Stack>
                        </Stack>
                    ),
                    contentCommentInfo: (
                        <Stack direction='column' justifyContent='center'>
                            <Typography variant='body2'>{obj.contentComment.message}</Typography>
                            {obj.contentComment.user && (
                                <Typography variant='caption'>
                                    <Link underline='hover' href={'/users/' + obj.contentComment.user.userId}>
                                        @{obj.contentComment.user.nickname}
                                    </Link>
                                </Typography>
                            )}
                        </Stack>
                    ),
                    reporter: (
                        <Stack direction='row' alignItems='center' spacing={0.5}>
                            <ProfileImage src={obj.user.url?.thumbnail} title={obj.user.nickname} />
                            <Typography variant='body2'>
                                <Link underline='hover' href={'users/' + obj.user.userId}>
                                    {obj.user.nickname}
                                </Link>
                            </Typography>
                        </Stack>
                    ),
                    reason: (
                        <Stack direction='row' alignItems='center' spacing={0.25}>
                            <Chip
                                size='small'
                                label={obj.reason}
                                sx={{
                                    backgroundColor: CommonUtil.stringToColor(obj.reason),
                                    color: '#ffffff',
                                }}
                            />
                            {obj.reason === 'ETC' && (
                                <Tooltip title={obj.memo} placement='right' arrow>
                                    <HelpIcon fontSize='small' color='disabled' />
                                </Tooltip>
                            )}
                        </Stack>
                    ),
                    status: (
                        <Stack direction='row' alignItems='center' spacing={0.25}>
                            <Chip
                                size='small'
                                label={obj.status}
                                color={
                                    ({
                                        REPORTED: 'default',
                                        COMPLETE: 'success',
                                        REJECTED: 'error',
                                    }[obj.status as string] as 'success' | 'warning' | 'default') || 'default'
                                }
                            />
                            {obj.status !== 'REPORTED' && (
                                <Tooltip title={obj.comment} placement='right' arrow>
                                    <ErrorIcon
                                        fontSize='small'
                                        color={
                                            ({
                                                REPORTED: 'inherit',
                                                COMPLETE: 'success',
                                                REJECTED: 'error',
                                            }[obj.status as string] as 'success' | 'warning' | 'inherit') || 'inherit'
                                        }
                                    />
                                </Tooltip>
                            )}
                        </Stack>
                    ),
                    reportedAt: (
                        <div>
                            <Typography component='div' variant='caption'>
                                {DateFormatter.toFormattedDate(obj.createdAt as string)}
                            </Typography>
                        </div>
                    ),
                    actionButtons: (
                        <Stack direction='row' spacing={1}>
                            <IconButton
                                size='small'
                                onClick={(e) => handleMenuOpen(obj.contentComment.contentCommentId, obj.user.userId, e)}
                                disabled={obj.status !== 'REPORTED' ? true : false}>
                                <MoreVertIcon />
                            </IconButton>
                        </Stack>
                    ),
                }));
                setListData(data);
            })
            .catch((e: any) => {
                alert.error(e.message);
            });
    }, [search, alert, api, theme]);

    const listColumn: ListColumnType[] = [
        { column: 'id', isHidden: true, idColumn: true },
        { column: 'contentInfo', title: '컨텐츠' },
        { column: 'contentCommentInfo', title: '댓글' },
        { column: 'reporter', title: '신고자' },
        { column: 'reason', title: '신고 사유' },
        { column: 'status', title: '처리 상태' },
        { column: 'reportedAt', title: '신고일' },
        { column: 'actionButtons', title: '' },
    ];

    return (
        <ListPage pageTitle='컨텐츠 댓글 신고 리스트'>
            <ListPagination listData={listData} currentPage={search.page}>
                {listData.content && <TableListItems items={listData.content} listColumn={listColumn}></TableListItems>}
            </ListPagination>
            <CommentReportsMenu
                contentCommentId={contentCommentId}
                userId={userId}
                open={menuOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
            />
        </ListPage>
    );
};

export default CommentReports;
