import React, { useState, useEffect, useContext } from 'react';
import { ChannelType } from '~/types/ChannelType';
import { ApiContext } from '~/contexts/ApiContext';
import ViewPage from '~/components/view/ViewPage';
import { PageTitleProps } from '~/components/common/PageTitle';
import { IconButton, Link, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { UserType } from '~/types/UserType';
import { ProfileImage } from '~/components/common/ProfileImage';
import { MoreHorizIcon } from '~/assets/Icons';


type Props = {
    channel?: ChannelType;
};
const UserChannelFollowers = (props: Props) => {
    const api = useContext(ApiContext);
    const channel: ChannelType = props.channel!;
    const [followerList, setFollowerList] = useState<UserType[]>([]);
    const search = { page: 1, size: 5 };
    useEffect(() => {
        api.get(`/channels/${channel.channelId}/followers`, search).then((data) => {
            console.log(data);
            setFollowerList(data.content);
        });
    }, [channel, api]);

    const pageTitleProps: PageTitleProps = {
        component: 'h2',
        variant: 'subtitle1',
        color: 'default',
    };

    return (
        <ViewPage
            pageTitle='팔로워'
            pageTitleProps={pageTitleProps}
            pageActionButton={
                (channel.followerCount as number) > followerList.length ? (
                    <Link href={`/channels/${channel.channelId}#followers`}>
                        <IconButton aria-label='더보기' size='small'>
                            <MoreHorizIcon fontSize='small' />
                        </IconButton>
                    </Link>
                ) : (
                    ''
                )
            }>
            <List dense={true}>
                {followerList.map((follower) => (
                    <ListItem key={follower.userId}>
                        <ListItemAvatar>
                            <ProfileImage src={follower.url?.thumbnail} title={follower.nickname} />
                        </ListItemAvatar>
                        <ListItemText primary={follower.nickname} />
                    </ListItem>
                ))}
            </List>
        </ViewPage>
    );
};

export default UserChannelFollowers;
