import React, { useState, useEffect, useContext } from 'react';
import { ChannelType } from '~/types/ChannelType';
import { ApiContext } from '~/contexts/ApiContext';

import { AvatarGroup, Grid, Typography } from '@mui/material';
import { UserType } from '~/types/UserType';
import { ProfileImage } from '~/components/common/ProfileImage';

type Props = {
    channel?: ChannelType;
};
const UserChannelMembers = (props: Props) => {
    const api = useContext(ApiContext);
    const channel: ChannelType = props.channel!;
    const [memberList, setMemberList] = useState<UserType[]>([]);
    const [total, setTotal] = useState(0);
    const search = { page: 1, size: 5 };

    useEffect(() => {
        api.get(`/channels/${channel.channelId}/members`, search).then((data) => {
            setMemberList(data.content);
            setTotal(data.totalElements);
        });
    }, [channel, api]);

    if (memberList.length > 1) {
        return (
            <Grid container>
                <Grid item md={12}>
                    <Typography component='h1' variant='caption'>
                        Members
                    </Typography>
                    <div>
                        <AvatarGroup
                            total={total}
                            max={5}
                            spacing='medium'
                            sx={{ flexDirection: 'row-reverse', justifyContent: 'flex-end' }}>
                            {memberList.map((member) => (
                                <ProfileImage
                                    sx={{ width: 40, height: 40 }}
                                    src={member.url?.thumbnail}
                                    title={member.nickname}
                                    key={member.userId}
                                />
                            ))}
                        </AvatarGroup>
                    </div>
                </Grid>
            </Grid>
        );
    } else {
        return <></>;
    }
};

export default UserChannelMembers;
