import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

type Props = {
    open: boolean;
    title?: string;
    content?: React.ReactNode;
    actionButton?: React.ReactNode;
    onClose?: () => void;
};

const ActionDialog = (props: Props) => {
    const [dialogOpen, setDialogOpen] = useState(props.open);
    const dialogTitle = props.title;
    const dialogContent = props.content;

    const actionButton = props.actionButton;
    const handleClose = () => {
        setDialogOpen(false);
        if (props.onClose !== undefined) {
            props.onClose();
        }
    };
    
    useEffect(() => {
        setDialogOpen(props.open);
    }, [props]);

    return (
        <Dialog fullWidth={true} open={dialogOpen} onClose={handleClose}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>{dialogContent}</DialogContent>
            <DialogActions>
                <>
                    <Button variant='outlined' onClick={handleClose}>
                        Cancel
                    </Button>
                    {actionButton && <>{actionButton}</>}
                </>
            </DialogActions>
        </Dialog>
    );
};

export default ActionDialog;
