import React, { useContext, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { PageTitleProps } from '~/components/common/PageTitle';
import { ThumbnailImage } from '~/components/common/ThumbnailImage';
import ListPage from '~/components/list/ListPage';
import ListPagination, { PaginationListType } from '~/components/list/ListPagination';
import TableListItems, { ListColumnType } from '~/components/list/TableListItems';
import ViewPage from '~/components/view/ViewPage';
import { ApiContext } from '~/contexts/ApiContext';
import { UserType } from '~/types/UserType';
import { DateFormatter } from '~/utils/DateFormatter';

type Props = {
    user?: UserType;
};

const UserComment = (props: Props) => {
    const api = useContext(ApiContext);
    const user = props.user;
    const [listData, setListData] = useState<PaginationListType>({});

    const [page, setPage] = useState(1);
    const [size] = useState(10);

    const handlePage = (page: number) => {
        setPage(page);
    };

    useEffect(() => {
        api.get(`/users/${user?.userId}/comments`, { page: page, size: size }).then((data) => {
            data.content = data.content?.map((obj: any) => ({
                ...obj,
                id: obj.contentCommentId,
                contentInfo: (
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <ThumbnailImage src={obj.content.url?.thumbnail} alt={obj.content.title} />
                        <Typography variant='body2'>{obj.content.title}</Typography>
                    </Stack>
                ),
                createdAt: DateFormatter.toFormattedDate(obj.createdAt as string),
            }));
            setListData(data);
        });
    }, [user, api, setListData, page, size]);

    const pageTitleProps: PageTitleProps = {
        component: 'h2',
        variant: 'subtitle1',
        color: 'default',
    };

    const listColumn: ListColumnType[] = [
        { column: 'id', idColumn: true, title: 'ID' },
        { column: 'contentInfo', title: '컨텐츠' },
        { column: 'message', title: '댓글' },
        { column: 'createdAt', title: '작성일' },
    ];

    return (
        <ViewPage pageTitle='작성한 댓글' pageTitleProps={pageTitleProps}>
            {listData.totalElements !== undefined && listData.totalElements > 0 ? (
                <ListPage>
                    <ListPagination listData={listData} currentPage={page} handlePage={handlePage}>
                        {listData.content && (
                            <TableListItems items={listData.content} listColumn={listColumn}></TableListItems>
                        )}
                    </ListPagination>
                </ListPage>
            ) : (
                <Typography component='h2' variant='body2' textAlign='center' sx={{ marginTop: 2 }}>
                    작성한 댓글이 없습니다.
                </Typography>
            )}
        </ViewPage>
    );
};

export default UserComment;
