import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ViewPage from '~/components/view/ViewPage';
import { AlertContext } from '~/contexts/AlertContext';
import { ApiContext } from '~/contexts/ApiContext';
import { ProductType } from '~/types/ProductType';
import ProductInfo from './ProductInfo';
import ThreeDContentView from './ThreeDContentView';

const ScannedProduct = () => {
    const alert = useContext(AlertContext);
    const api = useContext(ApiContext);
    const [product, setProduct] = useState<ProductType>({} as ProductType);

    const { productId } = useParams();

    useEffect(() => {
        api.get(`/scanned-products/${productId}`)
            .then((data) => {
                console.log(data);
                setProduct(data);
            })
            .catch((e: any) => {
                alert.error(e.message, -1);
            });
    }, [productId, api, alert]);

    return (
        <ViewPage pageTitle='스캔 제품'>
            <Grid container spacing={1}>
                {product.status === 'COMPLETE' && (
                    <Grid item sm={12} md={8}>
                        <ThreeDContentView url={product.url?.src!} />
                    </Grid>
                )}
                <Grid item sm={12} md={product.status === 'COMPLETE' ? 4 : 12}>
                    {product.productId && <ProductInfo product={product} />}
                </Grid>
            </Grid>
        </ViewPage>
    );
};

export default ScannedProduct;
