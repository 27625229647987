import React from 'react';
import { MuiFileInput, MuiFileInputProps } from 'mui-file-input';
import { TControl } from '~/types/TControl';
import { FieldValues, useController } from 'react-hook-form';
import { Typography } from '@mui/material';

type TProps<T extends FieldValues> = MuiFileInputProps & TControl<T>;

function ValidFileInput<T extends FieldValues>({ name, rules, control, ...props }: TProps<T>) {
    const {
        field: { value, onChange, onBlur },
        fieldState: { isDirty, isTouched, error },
    } = useController({ name, rules, control });

    return (
        <>
            <MuiFileInput value={value} onChange={onChange} error={error ? true : false} {...props} />
            <Typography variant='caption' color='error'>
                {error ? error.message : ''}
            </Typography>
        </>
    );
}

export default ValidFileInput;
