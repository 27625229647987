import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '~/contexts/ApiContext';
import { useParams } from 'react-router-dom';
import { TipType } from '~/types/TipType';
import { AlertContext } from '~/contexts/AlertContext';
import TipForm from './TipForm';

const TipEdit = () => {
    const alert = useContext(AlertContext);
    const api = useContext(ApiContext);
    const [tip, setTip] = useState<TipType>({
        title: '',
        isNotice: false,
        message: '',
    } as TipType);

    const { tipId } = useParams();
    useEffect(() => {
        api.get(`/tips/${tipId}`)
            .then((data) => {
                setTip(data);
            })
            .catch((e: any) => {
                alert.error(e.message, -1);
            });
    }, [tipId, api, alert]);

    return <TipForm tip={tip} />;
};

export default TipEdit;
