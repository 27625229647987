import { Avatar, useTheme } from '@mui/material';
import { CommonUtil } from '~/utils/CommonUtil';

export const ProfileImage = ({ children, title, sx, ...props }: any) => {
    const theme = useTheme();
    const letters = typeof children === 'string' ? children : title;

    if (letters) {
        sx = { bgcolor: letters ? CommonUtil.stringToColor(letters) : theme.palette.grey['A100'], ...sx };
    }
    return (
        <Avatar
            component='div'
            {...props}
            sx={[{ width: 35, height: 35, border: `2px solid ${theme.palette.grey['A100']}` }, sx]}>
            {typeof children === 'string' ? children[0]?.toUpperCase() : children || title?.[0]?.toUpperCase()}
        </Avatar>
    );
};
