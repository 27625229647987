import { Button, Card, Grid, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ViewPage from '~/components/view/ViewPage';
import { ActionDialogContext } from '~/contexts/ActionDialogContext';
import { AlertContext } from '~/contexts/AlertContext';
import { ApiContext } from '~/contexts/ApiContext';
import { ContentType } from '~/types/ContentType';
import ContentInfo from './ContentInfo';
import ThreeDContentView from './ThreeDContentView';

const PostedContent = () => {
    const alert = useContext(AlertContext);
    const api = useContext(ApiContext);
    const [content, setContent] = useState<ContentType>({} as ContentType);

    const { contentId } = useParams();
    const navigate = useNavigate();
    const actionDialog = useContext(ActionDialogContext);

    const handleDelete = () => {
        api.delete(`/posted-contents/${contentId}`)
            .then(() => {
                alert.success('삭제 되었습니다', -1);
                actionDialog.closeDialog();
            })
            .catch((e: any) => {
                alert.error(e.message);
            });
    };

    const clickDelete = () => {
        const actionButton = (
            <Button variant='contained' onClick={handleDelete} color='error'>
                삭제
            </Button>
        );
        actionDialog.action('게시된 3D 컨텐츠 삭제', '정말 삭제하시겠습니까?', actionButton);
    };

    useEffect(() => {
        api.get(`/posted-contents/${contentId}`)
            .then((data) => {
                console.log(data);
                setContent(data);
            })
            .catch((e: any) => {
                alert.error(e.message, -1);
            });
    }, [contentId, api, alert]);

    return (
        <ViewPage pageTitle='게시된 3D 컨텐츠'>
            <Card variant='outlined' sx={{ p: 2 }}>
                <Grid container spacing={1}>
                    <Grid item sm={12} md={8}>
                        {content.contentId && <ThreeDContentView url={content.url?.src!} />}
                    </Grid>
                    <Grid item sm={12} md={4}>
                        {content.contentId && <ContentInfo content={content} />}
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={2} direction='row' justifyContent='flex-end'>
                            <Button variant='contained' onClick={clickDelete} color='error'>
                                삭제
                            </Button>
                            <Button variant='outlined' onClick={() => navigate(-1)}>
                                뒤로
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Card>
        </ViewPage>
    );
};

export default PostedContent;
