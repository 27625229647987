import { Link, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { PageTitleProps } from '~/components/common/PageTitle';
import { ProfileImage } from '~/components/common/ProfileImage';
import ListPage from '~/components/list/ListPage';
import ListPagination, { PaginationListType } from '~/components/list/ListPagination';
import TableListItems, { ListColumnType } from '~/components/list/TableListItems';
import ViewPage from '~/components/view/ViewPage';
import { AlertContext } from '~/contexts/AlertContext';
import { ApiContext } from '~/contexts/ApiContext';
import { ChannelType } from '~/types/ChannelType';
import { DateFormatter } from '~/utils/DateFormatter';

type Props = {
    channel: ChannelType;
};

const ChannelMembers = ({ channel }: Props) => {
    const api = useContext(ApiContext);
    const alert = useContext(AlertContext);
    const [listData, setListData] = useState<PaginationListType>({});

    const [page, setPage] = useState(1);
    const [size] = useState(10);

    const handlePage = (page: number) => {
        setPage(page);
    };

    useEffect(() => {
        api.get(`/channels/${channel?.channelId}/members`, { page: page, size: size })
            .then((data) => {
                data.content = data.content?.map((obj: any) => ({
                    ...obj,
                    id: obj.userId,
                    userLInk: (
                        <Link underline='hover' href={'/users/' + obj.userId}>
                            {obj.userId}
                        </Link>
                    ),
                    userInfo: (
                        <Stack direction='row' spacing={0.5} alignItems='center'>
                            <ProfileImage src={obj.url?.thumbnail} title={obj.nickname} />
                            <Typography variant='body2'>{obj.nickname}</Typography>
                        </Stack>
                    ),

                    createdAt: DateFormatter.toFormattedDate(obj.createdAt as string),
                    loggedAt: DateFormatter.toFormattedDate(obj.loggedAt as string),
                }));
                setListData(data);
            })
            .catch((e: any) => {
                alert.error(e.message, -1);
            });
    }, [api, channel, page, size, setListData, alert]);

    const listColumn: ListColumnType[] = [
        { column: 'id', isHidden: true, idColumn: true },
        { column: 'userLInk', title: 'User ID' },
        { column: 'userInfo', title: '사용자' },
        { column: 'createdAt', title: '가입일' },
        { column: 'loggedAt', title: '마지막 로그인' },
    ];

    const pageTitleProps: PageTitleProps = {
        component: 'h2',
        variant: 'subtitle1',
        color: 'default',
    };

    return (
        <ViewPage pageTitle='채널 멤버' pageTitleProps={pageTitleProps}>
            {listData.totalElements !== undefined && listData.totalElements > 0 ? (
                <ListPage>
                    <ListPagination listData={listData} currentPage={page} handlePage={handlePage}>
                        {listData.content && (
                            <TableListItems items={listData.content} listColumn={listColumn}></TableListItems>
                        )}
                    </ListPagination>
                </ListPage>
            ) : (
                <Typography component='h2' variant='body2' textAlign='center' sx={{ marginTop: 2 }}>
                    멤버가 없습니다.
                </Typography>
            )}
        </ViewPage>
    );
};

export default ChannelMembers;
