import '@google/model-viewer';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import '~/ModelViewer.css';

type Props = {
    url: string;
};

const check3DData = async (url: string): Promise<boolean> => {
    try {
        const res: any = await fetch(url, {
            method: 'GET',
        });

        if (res.ok) {
            if (res.headers.get('Content-Length') <= 0) {
                return false;
            }
        }
        return res.ok;
    } catch (e) {
        return false;
    }
};

const ModelView = (props: Props) => {
    const [isValidUrl, setIsValidUrl] = useState<boolean>(false);

    useEffect(() => {
        check3DData(props.url).then((data) => {
            setIsValidUrl(data);
        });
    }, [props.url, setIsValidUrl]);

    if (isValidUrl) {
        return (
            <>
                <model-viewer src={props.url} camera-controls class='model-view' autoplay />
            </>
        );
    } else {
        return (
            <>
                <Typography component='div' textAlign='center'>
                    3D 데이터가 정상적이지 않습니다.
                </Typography>
            </>
        );
    }
};

export default ModelView;
