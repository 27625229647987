import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '~/contexts/ApiContext';
import { useParams } from 'react-router-dom';
import ViewPage from '~/components/view/ViewPage';
import { Grid } from '@mui/material';
import UserInfo from '~/pages/user/UserInfo';
import UserDetail from '~/pages/user/UserDetail';
import { UserType } from '~/types/UserType';

const User = () => {
    const api = useContext(ApiContext);
    const [user, setUser] = useState<UserType>({});
    const { userId } = useParams();

    useEffect(() => {
        api.get(`/users/${userId}`).then((data) => {
            setUser(data);
        });
    }, [userId, api]);

    return (
        <ViewPage pageTitle='사용자 정보'>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    {user.userId && <UserInfo user={user} />}
                </Grid>
                <Grid item xs={8}>
                    {user.userId && <UserDetail user={user} />}
                </Grid>
            </Grid>
        </ViewPage>
    );
};

export default User;
