import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import apiConfigs from '~/configs/apiConfigs';
import { JsonSerializer } from '~/utils/JsonSerializer';
import { useAuth } from './useAuth';

async function parseResult(res: any, invalidTokenCallback: any) {
    let result = null;

    try {
        const text = await res.text();
        // console.debug(text);
        if (text) {
            result = JsonSerializer.deserialize(text);
        }
    } catch (e: any) {
        throw new Error(e.message ? e.message : '잘못된 요청입니다');
    }

    if (res.ok) {
        return result;
    }

    if (result?.status === 401 && result?.message === 'Invalid token') {
        invalidTokenCallback();
    }
    throw result;
}

export const useApi = () => {
    const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
    const API_PATH = `${API_DOMAIN}/manager`;
    const manager = useSelector((state: any) => state.authState.manager || {});
    const [token, setToken] = useState(manager.token);

    if (manager.token !== token) {
        setToken(manager.token);
    }

    const { signOut } = useAuth();

    const clearToken = useCallback(() => {
        signOut();
    }, [signOut]);

    return useMemo(() => {
        return {
            get: async (path: string, params?: any) => {
                //const queries = Object.entries(params || {}).map(([k, v]) => `${k}=${v ? encodeURIComponent(v as string) : ''}`);
                const queries = Object.entries(params || {}).map(
                    ([k, v]) =>
                        `${k}=${
                            v
                                ? typeof v == 'object'
                                    ? encodeURIComponent(JsonSerializer.serialize(v))
                                    : encodeURIComponent(v as string)
                                : ''
                        }`
                );

                const url = `${API_PATH}/${path.replace(/^\//, '')}${path.includes('?') ? '&' : '?'}${queries.join(
                    '&'
                )}`;

                const res = await fetch(url, {
                    method: 'GET',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token || apiConfigs.guestToken}`,
                    },
                });
                return await parseResult(res, () => clearToken());
            },
            post: async (path: string, params?: any) => {
                const headers: any = {
                    Authorization: `Bearer ${token || apiConfigs.guestToken}`,
                };

                var bodyData = params;
                if (!(params instanceof FormData)) {
                    headers['Content-Type'] = 'application/json';
                    bodyData = JsonSerializer.serialize(params);
                }

                var postApiPath = API_PATH;

                if (path === '/sign-in') {
                    postApiPath = API_DOMAIN!;
                }

                const res = await fetch(`${postApiPath}/${path.replace(/^\//, '')}`, {
                    method: 'POST',
                    cache: 'no-cache',
                    body: bodyData,
                    headers: headers,
                });

                return await parseResult(res, () => clearToken());
            },
            delete: async (path: string) => {
                const res = await fetch(`${API_PATH}/${path.replace(/^\//, '')}`, {
                    method: 'DELETE',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token || apiConfigs.guestToken}`,
                    },
                });
                return await parseResult(res, () => clearToken());
            },
        };
    }, [token, clearToken]);
};
