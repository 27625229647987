import { Grid, IconButton, InputBase, Paper } from '@mui/material';
import React from 'react';
import { SearchIcon } from '~/assets/Icons';

type Props = {
    handleSubmit?: (e: any) => Promise<void>;
    placeHolder?: string;
    children?: React.ReactNode;
};
const ListSearchBar = ({ handleSubmit, placeHolder, children }: Props) => {
    return (
        <Grid container direction='row'>
            <Grid item sm={12}>
                {children ? (
                    children
                ) : (
                    <Paper
                        elevation={0}
                        component='form'
                        sx={{
                            p: '2px 4px',
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                        }}
                        onSubmit={handleSubmit}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder={placeHolder}
                            inputProps={{ 'aria-label': placeHolder }}
                            name='search_value'
                        />
                        <IconButton type='submit' sx={{ p: '10px' }} aria-label='search'>
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                )}
            </Grid>
        </Grid>
    );
};

export default ListSearchBar;
