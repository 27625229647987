import * as React from 'react';
import Typography from '@mui/material/Typography';

export type PageTitleProps = {
    children?: React.ReactNode;
    component?: any;
    variant?: any;
    color?: any;
};

export default function PageTitle(props: PageTitleProps) {
    const variant = props.variant ? props.variant : 'h6';
    const component = props.component ? props.component : 'h2';
    const color = props.color ? props.color : 'default';

    return (
        <Typography component={component} variant={variant} color={color} gutterBottom>
            {props.children}
        </Typography>
    );
}
