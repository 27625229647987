import { Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

type Props = {
    value: String;
};

const HiddenValue = ({ value }: Props) => {
    const [isShow, setIsShow] = useState<boolean>(false);
    const handleShow = (isShow: boolean) => {
        setIsShow(isShow);
    };

    return (
        <Stack direction='column' spacing={1}>
            <div>
                <Button
                    variant='outlined'
                    size='small'
                    sx={{ padding: 0, minWidth: 50 }}
                    onClick={() => handleShow(isShow === true ? false : true)}
                    color={isShow === true ? 'error' : 'primary'}
                    disabled={value === null || value === '' ? true : false}>
                    {isShow === true ? '숨기기' : '보기'}
                </Button>
            </div>
            {isShow === true && (
                <Typography sx={{ wordBreak: 'break-all' }} component='div' variant='caption'>
                    {value}
                </Typography>
            )}
        </Stack>
    );
};

export default HiddenValue;
