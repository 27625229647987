import React, { useContext, useEffect, useState } from 'react';
import { Chip, Link, Stack, Tooltip, Typography } from '@mui/material';
import { ErrorIcon, HelpIcon } from '~/assets/Icons';
import { PageTitleProps } from '~/components/common/PageTitle';
import { ThumbnailImage } from '~/components/common/ThumbnailImage';
import ListPage from '~/components/list/ListPage';
import ListPagination, { PaginationListType } from '~/components/list/ListPagination';
import TableListItems, { ListColumnType } from '~/components/list/TableListItems';
import ViewPage from '~/components/view/ViewPage';
import { ApiContext } from '~/contexts/ApiContext';
import { UserType } from '~/types/UserType';
import { CommonUtil } from '~/utils/CommonUtil';
import { DateFormatter } from '~/utils/DateFormatter';

type Props = {
    user?: UserType;
};

const UserReportContent = (props: Props) => {
    const user = props.user;
    const api = useContext(ApiContext);
    const [listData, setListData] = useState<PaginationListType>({});

    const [page, setPage] = useState(1);
    const [size] = useState(5);

    const handlePage = (page: number) => {
        setPage(page);
    };

    useEffect(() => {
        api.get(`/users/${user?.userId}/reports`, { page: page, size: size }).then((data) => {
            data.content = data.content.map((obj: any) => ({
                ...obj,
                id: obj.content.contentId.toString() + '_' + obj.user.userId.toString(),
                contentInfo: (
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <ThumbnailImage src={obj.content.url?.thumbnail} alt={obj.content.title} />
                        <Stack direction='column' justifyContent='center'>
                            <Typography variant='body2'>{obj.content.title}</Typography>
                            <Typography variant='caption'>
                                <Link underline='hover' href={'/users/' + obj.content.userId}>
                                    @{obj.content.channel.name}
                                </Link>
                            </Typography>
                        </Stack>
                    </Stack>
                ),
                reason: (
                    <Stack direction='row' alignItems='center' spacing={0.25}>
                        <Chip
                            size='small'
                            label={obj.reason}
                            sx={{
                                backgroundColor: CommonUtil.stringToColor(obj.reason),
                                color: '#ffffff',
                            }}
                        />
                        {obj.reason === 'ETC' && (
                            <Tooltip title={obj.memo} placement='right' arrow>
                                <HelpIcon fontSize='small' color='disabled' />
                            </Tooltip>
                        )}
                    </Stack>
                ),
                status: (
                    <Stack direction='row' alignItems='center' spacing={0.25}>
                        <Chip
                            size='small'
                            label={obj.status}
                            sx={{ backgroundColor: CommonUtil.stringToColor(obj.status), color: '#ffffff' }}
                        />
                        {obj.status !== 'REPORTED' && (
                            <Tooltip title={obj.comment} placement='right' arrow>
                                <ErrorIcon fontSize='small' color='disabled' />
                            </Tooltip>
                        )}
                    </Stack>
                ),
                createdAt: DateFormatter.toFormattedDate(obj.createdAt as string),
            }));

            setListData(data);
        });
    }, [api, user, page, size, setListData]);

    const listColumn: ListColumnType[] = [
        { column: 'id', isHidden: true, idColumn: true },
        { column: 'contentInfo', title: '컨텐츠' },
        { column: 'reason', title: '신고 사유' },
        { column: 'status', title: '처리 상태' },
        { column: 'createdAt', title: '신고일' },
    ];

    const pageTitleProps: PageTitleProps = {
        component: 'h2',
        variant: 'subtitle1',
        color: 'default',
    };

    return (
        <ViewPage pageTitle='컨텐츠 신고' pageTitleProps={pageTitleProps}>
            {listData.totalElements !== undefined && listData.totalElements > 0 ? (
                <ListPage>
                    <ListPagination listData={listData} currentPage={page} handlePage={handlePage}>
                        {listData.content && (
                            <TableListItems items={listData.content} listColumn={listColumn}></TableListItems>
                        )}
                    </ListPagination>
                </ListPage>
            ) : (
                <Typography component='h2' variant='body2' textAlign='center' sx={{ marginTop: 2 }}>
                    접수한 신고가 없습니다.
                </Typography>
            )}
        </ViewPage>
    );
};

export default UserReportContent;
