import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type machineIdState = {
    machineIdState: string;
};

const initialState: machineIdState = {
    machineIdState: '',
};

export const machineIdStateSlice = createSlice({
    name: 'machineIdState',
    initialState,
    reducers: {
        setMachineIdState: (state, action: PayloadAction<string>) => {
            state.machineIdState = action.payload;
        },
    },
});

export const { setMachineIdState } = machineIdStateSlice.actions;

export default machineIdStateSlice.reducer;
