import { Button, Menu, MenuItem } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BorderColorIcon, DeleteForeverIcon } from '~/assets/Icons';
import { ActionDialogContext } from '~/contexts/ActionDialogContext';
import { AlertContext } from '~/contexts/AlertContext';
import { ApiContext } from '~/contexts/ApiContext';

type Props = {
    tipId: number | null;
    anchorEl: Element | null;
    open: boolean;
    onClose?: () => void;
};

const TipsMenu = (props: Props) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const handleMenuClose = () => {
        setOpen(false);
        if (props.onClose !== undefined) {
            props.onClose();
        }
    };
    const api = useContext(ApiContext);
    const actionDialog = useContext(ActionDialogContext);
    const alert = useContext(AlertContext);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <Menu
            id='item-action-menu'
            anchorEl={props.anchorEl}
            open={open}
            onClose={handleMenuClose}
            PaperProps={{
                style: {
                    width: 120,
                },
            }}>
            <MenuItem key={'edit_item'} onClick={() => navigate('/tips/' + props.tipId)}>
                <BorderColorIcon color='info' fontSize='small' sx={{ marginRight: 2 }} /> 수정
            </MenuItem>
            <MenuItem
                key={'delete_item'}
                onClick={() => {
                    actionDialog.action(
                        'Tip 삭제',
                        '정말 삭제하시겠습니까?',
                        <Button
                            variant='contained'
                            color='error'
                            onClick={() => {
                                api.delete('/tips/' + props.tipId)
                                    .then(() => {
                                        alert.success('삭제 되었습니다');
                                        actionDialog.closeDialog();
                                        handleMenuClose();
                                    })
                                    .catch((e: any) => {
                                        alert.error(e.message);
                                        actionDialog.closeDialog();
                                        handleMenuClose();
                                    });
                            }}>
                            삭제
                        </Button>
                    );
                }}>
                <DeleteForeverIcon color='error' fontSize='small' sx={{ marginRight: 2 }} /> 삭제
            </MenuItem>
        </Menu>
    );
};

export default TipsMenu;
