import { useContext, useEffect, useState } from 'react';
import { ApiContext } from '~/contexts/ApiContext';
import ListPage from '~/components/list/ListPage';
import TableListItems, { ListColumnType } from '~/components/list/TableListItems';
import ListPagination, { PaginationListType } from '~/components/list/ListPagination';
import { useQueryParams, NumberParam, withDefault } from 'use-query-params';
import { Button, Chip, IconButton, Link, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AlertContext } from '~/contexts/AlertContext';
import { MoreVertIcon } from '~/assets/Icons';
import { ActionDialogContext } from '~/contexts/ActionDialogContext';
import { DateFormatter } from '~/utils/DateFormatter';
import TipsMenu from './TipsMenu';

const Tips = () => {
    const api = useContext(ApiContext);
    const [listData, setListData] = useState<PaginationListType>({});
    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [tipId, setTipId] = useState<number | null>(null);
    const navigate = useNavigate();
    const [search] = useQueryParams({
        page: withDefault(NumberParam, 1),
        size: withDefault(NumberParam, 10),
    });
    const alert = useContext(AlertContext);
    const actionDialog = useContext(ActionDialogContext);

    const handleMenuOpen = (tipId: number, e: any) => {
        setTipId(tipId);
        setMenuOpen(true);
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setMenuOpen(false);
    };

    useEffect(() => {
        api.get('/tips', search)
            .then((data) => {
                data.content = data.content?.map((obj: any) => ({
                    ...obj,
                    id: obj.tipId,
                    tipLink: (
                        <Link underline='hover' href={`/tips/${obj.tipId}`}>
                            {obj.tipId}
                        </Link>
                    ),
                    actionButtons: (
                        <Stack direction='row' spacing={1}>
                            <IconButton size='small' onClick={(e) => handleMenuOpen(obj.tipId, e)}>
                                <MoreVertIcon />
                            </IconButton>
                        </Stack>
                    ),
                    isNotice: obj.isNotice ? <Chip size='small' label='notice' color='warning' /> : '',
                    changedAt: (
                        <div>
                            <Typography component='div' variant='caption'>
                                {DateFormatter.toFormattedDate(obj.createdAt as string)}
                            </Typography>
                            <Typography component='div' variant='caption'>
                                {DateFormatter.toFormattedDate(obj.updatedAt as string)}
                            </Typography>
                        </div>
                    ),
                }));
                setListData(data);
            })
            .catch((e: any) => {
                alert.error(e.message, -1);
            });
    }, [search, actionDialog, alert, api, navigate]);

    const listColumn: ListColumnType[] = [
        { column: 'id', isHidden: true, idColumn: true },
        { column: 'tipLink', title: 'Tip ID' },
        { column: 'title', title: '제목' },
        { column: 'isNotice', title: '공지' },
        { column: 'hit', title: '조회수' },
        { column: 'commentCount', title: '댓글수' },
        { column: 'changedAt', title: '작성일 / 수정일' },
        { column: 'actionButtons', title: '' },
    ];

    return (
        <ListPage pageTitle='Tip 리스트'>
            <ListPagination
                listData={listData}
                currentPage={search.page}
                buttonNodes={
                    <Button variant='outlined' onClick={() => navigate('/tips/add')}>
                        등록
                    </Button>
                }>
                {listData.content && <TableListItems items={listData.content} listColumn={listColumn}></TableListItems>}
            </ListPagination>
            <TipsMenu tipId={tipId} open={menuOpen} anchorEl={anchorEl} onClose={handleClose} />
        </ListPage>
    );
};

export default Tips;
